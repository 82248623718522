import React, { useEffect, useMemo, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';

import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import { SupplierMarker } from 'src/pages/maps/SupplierMarker';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';
import useSupplierGeoAnalytics from 'src/components/project/explore/hooks/useSupplierGeoAnalytics';

import useMap from './hooks/useMap';
import { MapContext } from './MapContext';
import { SupplierCountMarker } from './SupplierCountMarker';

const ZOOM_LEVEL_TDS = 'supplier';
const ZOOM_LEVEL_STATE = 'state';
const ZOOM_LEVEL_COUNTRY = 'country';
const ZOOM_LEVEL_REGION = 'region';

const EMPTY_ARRAY = [];

// for the popup on a marker, we can get away with raw html
const popupHtml = (spot, zoomLevel) =>
  zoomLevel !== ZOOM_LEVEL_TDS
    ? `<div>
<h3 style="margin: 4px;">${spot.label}</h3>
<table style="padding: 8px"><tbody>
<tr><td>Total&nbsp;Suppliers</td><td style="padding: 0 8px; text-align: right">${
        spot.suppliers
      }</td></tr>
<tr><td>Verified&nbsp;Suppliers</td><td style="padding: 0 8px; text-align: right">${
        spot.verified
      }</td></tr>
<tr><td>Total&nbsp;Capacity</td><td style="padding: 0 8px; text-align: right">${prettyNumberRound(
        spot.total_capacity_lbs / 2204
      )}&nbsp;mt</td></tr>
<tr><td>Verified&nbsp;Capacity</td><td style="padding: 0 8px; text-align: right">${prettyNumberRound(
        spot.verified_capacity_lbs / 2204
      )}&nbsp;mt</td></tr>
</tbody></table>
</div>`
    : `<div>
<h3 style="margin: 4px;">${spot.label}</h3>
<table style="padding: 8px"><tbody>
<tr><td>Total&nbsp;Capacity</td><td style="padding: 0 8px; text-align: right">${
        spot.verified_capacity_lbs || spot.total_capacity_lbs
          ? `${prettyNumberRound(
              (spot.verified_capacity_lbs || spot.total_capacity_lbs) / 2204
            )}&nbsp;mt`
          : 'Unknown'
      }</td></tr>
</tbody></table>`;

export default function MapPageWarehouseSupplier(props) {
  const { MapComponent } = useMapPageWarehouseSupplier(props);

  return MapComponent;
}

MapPageWarehouseSupplier.propTypes = {
  additionalMarkers: PropTypes.array,
  useMapProps: PropTypes.object
};

// FIXME duplicated from Suppliers
const labelMap = {
  all: ['accepted', null],
  accepted: ['accepted'],
  removed: ['removed']
};

export function useMapPageWarehouseSupplier({
  additionalMarkers,
  useMapProps,
  className,
  supplierFilters,
  ...props
}) {
  const filters = useRelevantFiltersForForm();

  const { map, mapContainer, zoom } = useMap({
    mapProps: {
      // Start zoomed out if no region filter
      zoom:
        !!filters?.region_code__in?.length ||
        !!filters?.country_code__in?.length
          ? 3
          : 1
    },
    ...(useMapProps || {})
  });
  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: true
  });
  const zoomLevel =
    (zoom < 2.5 && ZOOM_LEVEL_REGION) ||
    (zoom < 4 && ZOOM_LEVEL_COUNTRY) ||
    (zoom < 7 && ZOOM_LEVEL_STATE) ||
    ZOOM_LEVEL_TDS;

  const supplyZoomLevel = zoom >= 7;
  const nextZoomLevel = (level) => {
    if (level < 2.5) return 2.5;
    if (level < 4) return 4;
    if (level < 7) return 7;
    return level + 2;
  };

  const handleMarkerClick = (m, lat, lng) => {
    // new mapboxgl.Popup().setLngLat(coordinates).setHTML(description).addTo(map);
    m.flyTo({
      center: [lng, lat],
      zoom: nextZoomLevel(m.getZoom()),
      speed: 0.5
    });
  };

  // We only want to debounce sidebar filters changing but not zoom changing
  const { companySearch, filterResultValue, resultIndexFilter, parseFilters } =
    supplierFilters || {};

  const { data, isLoading } = useSupplierGeoAnalytics({
    zoomLevel,
    companySearch,
    resultIndexFilter,
    parseFilters,
    filterSupplierLabels: labelMap[filterResultValue]
  });
  const loadingClass = useLoadingStateClass(isLoading);

  // We want to pan and zoom map to fit the markers when records update due to
  // filters changing, but not when its due to the zoom level changing as this could
  // cause the map to infinitely zoom itself in and out
  const [shouldFitBounds, setShouldFitBounds] = useState(false);
  useEffect(() => {
    setShouldFitBounds(
      !!filters?.region_code__in?.length || !!filters?.country_code__in?.length
    );
  }, [companySearch, filterResultValue, filters]);
  useEffect(() => {
    if (map && data?.length && shouldFitBounds) {
      let centerLat = 0;
      let centerLng = 0;
      let minLat = 90;
      let maxLat = -90;
      let minLng = 180;
      let maxLng = -180;

      data.forEach((spot) => {
        centerLat += spot.lat;
        centerLng += spot.long;
        minLat = Math.min(spot.lat, minLat);
        maxLat = Math.max(spot.lat, maxLat);
        minLng = Math.min(spot.long, minLng);
        maxLng = Math.max(spot.long, maxLng);
      });
      centerLat /= data.length;
      centerLng /= data.length;

      map.flyTo({
        center: [centerLng, centerLat],
        zoom: 3,
        speed: 0.5
      });

      setShouldFitBounds(false);
    }
  }, [map, data]);

  const onMarkerMouseEnter = (spot) => {
    popup.remove();
    map.getCanvas().style.cursor = 'pointer';
    popup
      .setLngLat([spot.long, spot.lat])
      .setHTML(popupHtml(spot, zoomLevel))
      .addTo(map);
  };
  const onMarkerMouseLeave = (spot) => {
    map.getCanvas().style.cursor = '';
    popup.remove();
  };

  const bounds = map?.getBounds();
  const n = bounds?.getNorth();
  const s = bounds?.getSouth();
  const w = bounds?.getWest();
  const e = bounds?.getEast();
  const activeRecords = useMemo(
    () => {
      if (!map || !data || data.length === 0) return [];
      return data.filter(
        (o) => o.id && o.lat > s && o.lat < n && o.long > w && o.long < e
      );
    },
    [map, data, n, s, w, e]
    /* eslint-enable react/no-array-index-key */
  );
  const markers = useMemo(
    () => {
      if (!activeRecords || activeRecords.length === 0)
        return additionalMarkers || [];
      return [
        ...activeRecords.map((spot) =>
          supplyZoomLevel ? (
            <SupplierMarker
              supplier={{
                lat: spot.lat,
                long: spot.long
              }}
              primary={spot.verified > 0}
              id={spot.id}
              label={spot.label}
              key={spot.id}
              map={map}
              data={spot}
              handleMarkerClick={handleMarkerClick}
              onMouseEnter={() => onMarkerMouseEnter(spot)}
              onMouseLeave={() => onMarkerMouseLeave(spot)}
              suppliers={spot.suppliers}
            />
          ) : (
            <SupplierCountMarker
              supplier={{
                lat: spot.lat,
                long: spot.long
              }}
              id={spot.id}
              label={spot.label}
              key={spot.id}
              map={map}
              data={spot}
              handleMarkerClick={handleMarkerClick}
              onMouseEnter={() => onMarkerMouseEnter(spot)}
              onMouseLeave={() => onMarkerMouseLeave(spot)}
              suppliers={spot.suppliers}
              verified={spot.verified}
            />
          )
        ),
        ...(additionalMarkers || [])
      ];
    },
    [activeRecords, additionalMarkers]
    /* eslint-enable react/no-array-index-key */
  );

  return {
    map,
    activeRecords,
    MapComponent: (
      <MapContext.Provider value={map}>
        <div
          ref={mapContainer}
          {...props}
          className={`risk-map ${className} ${loadingClass}`}
        >
          {markers}
        </div>
      </MapContext.Provider>
    )
  };
}
