import React from 'react';
import FigmaEmbed from 'src/components/utils/FigmaEmbed';

function ProjectProcure() {
  return (
    <FigmaEmbed figmaUrl="https://www.figma.com/proto/MeYcUXk2uxS2H2KQqQS7Uc/Procure?page-id=2482%3A1815&node-id=2647-23137&node-type=frame&viewport=-27081%2C7294%2C0.85&t=wVskjmrsxtG9IFBv-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=2647%3A23137&show-proto-sidebar=1" />
  );
}

export default ProjectProcure;
