import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Empty, Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';

import Sidebar from 'src/components/layout/sidebar/Sidebar';
import ProjectDashboard from 'src/pages/project/ProjectDashboard';
import InsightsPage from 'src/pages/insights';

import 'src/pages/project/Project.less';
import emptyExplore from 'src/images/exploreEmpty.png';
import ProjectQuotes from 'src/pages/project/ProjectQuotes';
import { useUser } from 'src/utils/authentication';
import Portfolio from 'src/components/portfolio';
import ProposalModal from 'src/components/project/search/ProposalModal';
import MarketInsights from 'src/components/marketInsights';
import SupplierInsights from 'src/pages/supplier/SupplierInsights';
import SupplierProfile from 'src/pages/supplier/Profile';
import ProjectProcure from 'src/pages/project/details/ProjectProcure';
import ExploreRoutes from 'src/components/project/explore/ExploreRoutes';
import ProjectManage from 'src/pages/project/details/ProjectManage';
import SourceRoutes from 'src/components/project/source/SourceRoutes';
import ProcureRoutes from 'src/components/project/procure/ProcureRoutes';
import SidebarContextProvider from './sidebar/SidebarContext';

function NoAccess({ name }) {
  return (
    <Empty className="m-lg" image={emptyExplore} description="">
      <Typography.Title level={2}>
        You currently have no access to {name}
      </Typography.Title>
      <Typography.Paragraph>
        Please contact us to learn more.
      </Typography.Paragraph>
    </Empty>
  );
}

NoAccess.propTypes = {
  name: PropTypes.string
};

function AppLayoutSidebar() {
  const { data: user } = useUser();

  return (
    <div className="project-page">
      <SidebarContextProvider>
        <Sidebar disable={!user} />

        <div className="project-page-contents">
          {user ? undefined : <Skeleton />}
          <Routes>
            <Route path="/dashboard" element={<ProjectDashboard />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/market-insights" element={<MarketInsights />} />
            <Route path="/explore/*" element={<ExploreRoutes />} />
            <Route path="/source/*" element={<SourceRoutes />} />
            <Route path="/quotes/:projectId" element={<ProjectQuotes />} />
            <Route path="/proposal" element={<ProposalModal />} />
            <Route path="/procure" element={<ProjectProcure />} />
            <Route path="/procure/*" element={<ProcureRoutes />} />
            <Route path="/manage" element={<ProjectManage />} />
            <Route
              path="/supplier-insights/:supplierId?"
              element={<SupplierInsights />}
            />
            <Route
              path="/supplier/:supplierId?"
              element={<SupplierProfile />}
            />
            <Route path="/insights/:tab?" element={<InsightsPage />} />
          </Routes>
        </div>
      </SidebarContextProvider>
    </div>
  );
}

export default AppLayoutSidebar;
