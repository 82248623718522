import { Tag } from 'antd';
import React, { useMemo } from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import CapacityFilter from 'src/components/project/explore/filters/CapacityFilter';
import CountryFilter from 'src/components/project/explore/filters/CountryFilter';
import IsCapabilityFilter from 'src/components/project/explore/filters/IsCapabilityFilter';
import KpiFilter, {
  SingleKpiFilter
} from 'src/components/project/explore/filters/KpiFilter';
import MaterialFilter from 'src/components/project/explore/filters/MaterialFilter';
import PropertyFilter from 'src/components/project/explore/filters/PropertyFilter';
import RegionFilter from 'src/components/project/explore/filters/RegionFilter';
import usePrimaryKpis from 'src/components/project/hooks/usePrimaryKpis';
import { useSecondaryKpis } from 'src/components/project/hooks/useSecondaryKpis';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import { MetaPropertyName } from 'src/hooks/useMetaProperty';

export default function useTdsFilterOptions() {
  const [filters] = useConciergeContextState(['explore', 'filters']);

  const [form] = useConciergeContextState([
    'explore',
    'filters',
    'form_code__in'
  ]);

  const [materialType] = useConciergeContextState([
    'explore',
    'filters',
    'type_code__in'
  ]);

  const primaryKpis = usePrimaryKpis();
  const secondaryKpis = useSecondaryKpis();

  return useMemo(
    () => ({
      material: () => ({
        label: 'Material',
        extra: materialType && (
          <Tag color="blue">
            <MetaPropertyName property="type" optionValue={materialType} />
          </Tag>
        ),
        children: <MaterialFilter />
      }),
      capacity: () => ({
        label: 'Quantity',
        extra: filters?.capacity__gte && (
          <Tag color="blue">
            &gt; {prettyNumberRound(filters.capacity__gte / 2204)} mt
          </Tag>
        ),
        children: <CapacityFilter />
      }),
      region: () => ({
        label: 'Region',
        extra: filters?.region_code__in?.length && (
          <Tag color="blue">{filters.region_code__in.length}</Tag>
        ),
        children: <RegionFilter />
      }),
      country: () => ({
        label: 'Country',
        extra: filters?.country_code__in?.length && (
          <Tag color="blue">{filters.country_code__in.length}</Tag>
        ),
        children: <CountryFilter />
      }),
      primary_specs: () => ({
        label: 'Primary Specifications',
        extra: primaryKpis?.length ? (
          <Tag color="blue">{primaryKpis.join(', ')}</Tag>
        ) : null,
        children: (
          <div className="kpi-filter">
            {primaryKpis.map((kpiCode) => (
              <SingleKpiFilter key={kpiCode} kpiCode={kpiCode} isPrimary />
            ))}
          </div>
        ),
        className: 'ghost'
      }),
      secondary_specs: () => ({
        label: 'Secondary Specifications',
        extra: primaryKpis?.length ? (
          <Tag style={{ textWrap: 'wrap' }} color="blue">
            {secondaryKpis.join(', ')}
          </Tag>
        ) : null,
        children: (
          <KpiFilter title="Secondary Specs" excludeKpis={primaryKpis} />
        ),
        className: 'ghost'
      }),
      bale_source: () => ({
        label: 'Source',
        extra: filters?.properties?.bale_source?.length ? (
          <Tag color="blue">{filters.properties.bale_source.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="bale_source" />
      }),
      bale_form: () => ({
        label: 'Form',
        extra: filters?.properties?.bale_form?.length ? (
          <Tag color="blue">{filters.properties.bale_form.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="bale_form" />
      }),
      bale_grade: () => ({
        label: 'Grade',
        extra: filters?.properties?.bale_grade?.length ? (
          <Tag color="blue">{filters.properties.bale_grade.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="bale_grade" />
      }),
      color: () => ({
        label: 'Color',
        extra: filters?.properties?.color?.length ? (
          <Tag color="blue">{filters.properties.color.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="color" />
      }),
      verified: () => ({
        label: 'Verified Suppliers',
        extra:
          filters?.is_capability === false ? <Tag color="blue">Yes</Tag> : null,
        children: <IsCapabilityFilter />
      }),
      grade: () => ({
        label: 'Grade',
        extra: filters?.properties?.grade?.length ? (
          <Tag color="blue">{filters.properties.grade.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="grade" />
      }),
      'processing-method': () => ({
        label: 'Processing Method',
        extra: filters?.properties?.['processing-method']?.length ? (
          <Tag color="blue">
            {filters.properties['processing-method'].length}
          </Tag>
        ) : null,
        children: <PropertyFilter propertyCode="processing-method" />
      }),
      application: () => ({
        label: 'Application',
        extra: filters?.properties?.application?.length ? (
          <Tag color="blue">{filters.properties.application.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="application" />
      }),
      'source-type': () => ({
        label: 'Source Type',
        extra: filters?.properties?.['source-type']?.length ? (
          <Tag color="blue">{filters.properties['source-type'].length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="source-type" />
      }),
      additive: () => ({
        label: 'Additives / Fillers',
        extra: filters?.properties?.additive?.length ? (
          <Tag color="blue">{filters.properties.additive.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="additive" />
      }),
      'polymer-type': () => ({
        label: 'Polymer Type',
        extra: filters?.properties?.['polymer-type']?.length ? (
          <Tag color="blue">{filters.properties['polymer-type'].length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="polymer-type" />
      }),
      industry: () => ({
        label: 'Industry',
        extra: filters?.properties?.industry?.length ? (
          <Tag color="blue">{filters.properties.industry.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="industry" />
      }),
      certs: () => ({
        label: 'Certifications / Standards',
        extra: filters?.properties?.certs?.length ? (
          <Tag color="blue">{filters.properties.certs.length}</Tag>
        ) : null,
        children: (
          <PropertyFilter
            filterOptions={(opt) =>
              ![
                'efsa',
                'philippines_fda',
                'indonesia_fda',
                'fda_lno',
                'fda'
              ].includes(opt.code)
            }
            propertyCode="certs"
          />
        )
      }),
      flammability: () => ({
        label: 'Flammability Rating',
        extra: filters?.properties?.flammability?.length ? (
          <Tag color="blue">{filters.properties.flammability.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="flammability" />
      })
    }),
    [materialType, primaryKpis, filters, form]
  );
}

const tdsFilterOptionsMapResin = {
  'Primary Specs': {
    material: true,
    capacity: true,
    region: true,
    country: true,
    primary_specs: true
  },
  'Secondary Specs': {
    secondary_specs: true,
    _spread: true
  },
  'Additional Info': {
    color: true,
    grade: true,
    'processing-method': true,
    application: true,
    'source-type': true,
    additive: true,
    'polymer-type': true,
    industry: true,
    certs: true,
    flammability: true
  }
};

const tdsFilterOptionsMapBales = {
  Specifications: {
    material: true,
    capacity: true,
    region: true,
    country: true,
    color: true,
    bale_source: true
  },
  'Additional Info': {
    bale_form: true,
    bale_grade: true
  }
  // verified: true
};

const tdsFilterOptionsMapFlake = {
  'Primary Specs': {
    material: true,
    capacity: true,
    region: true,
    country: true,
    primary_specs: true
  },
  'Secondary Specs': {
    secondary_specs: true,
    _spread: true
  },
  'Additional Info': {
    bale_source: true,
    bale_form: true,
    bale_grade: true,
    color: true,
    grade: true,
    'processing-method': true,
    application: true,
    'source-type': true,
    additive: true,
    'polymer-type': true,
    industry: true,
    certs: true,
    flammability: true
    // verified: true
  }
};

const tdsFilterOptionsMap = {
  bales: tdsFilterOptionsMapBales,
  resin_pellets: tdsFilterOptionsMapResin,
  regrind_flakes: tdsFilterOptionsMapFlake
};

export function useTdsFilterActiveOptions(form) {
  return useMemo(() => tdsFilterOptionsMap[form] || {}, [form]);
}
