import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';

const percent = (n, d) => (d ? ((n || 0) / d) * 100 : 0);
export default function OutreachResults({ funnel }) {
  return (
    <>
      <Typography.Text className="heavy">
        Sent to {funnel[0].count} suppliers
      </Typography.Text>
      <Row
        className="search-results--supplier-outreach mb-xl"
        gutter={[16, 0]}
        wrap={false}
        align="stretch"
      >
        {funnel.slice(1).map((step, index) => (
          <Col flex="1" key={step.label}>
            <ExplorePanel>
              <Typography.Paragraph className="panel-title" strong>
                {step.label}
              </Typography.Paragraph>
              <div className="bottom">
                <span className="highlight">{step.count}</span>
                <span className="secondary">/{funnel[index]?.count || 0}</span>
              </div>
              <div className="rate">
                <span>
                  {prettyNumberRound(percent(step.count, funnel[index]?.count))}
                  %
                </span>{' '}
                {step.rateName}
              </div>
            </ExplorePanel>
          </Col>
        ))}
      </Row>
    </>
  );
}

OutreachResults.propTypes = {
  funnel: PropTypes.array
};
