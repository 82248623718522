import {
  DownloadOutlined,
  SearchOutlined,
  SettingFilled,
  TableOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { Button, Col, Radio, Row, Space, Typography, message } from 'antd';
import { saveAs } from 'file-saver';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getTdsCsv } from 'src/Query/warehouse';
import useGetWarehouseTdss, {
  useGetWarehouseTdssKey
} from 'src/Query/useGetWarehouseTdss';
import MapIcon from 'src/components/icons/MapIcon';
import EditTdsModal from 'src/pages/supplier/TechnicalDataSheet';
import OutreachResults from 'src/components/project/search/OutreachResults';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import { useTdsTable } from 'src/components/project/source/TdsTable';
import MapPageMarkers from 'src/pages/maps/MapPageMarkers';
import useGetProjectFunnel from 'src/Query/useGetProjectFunnel';
import DebouncedTextInput from 'src/components/form/DebouncedTextInput';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import { useUserIsInGroup } from 'src/utils/authentication';
import useIsConcierge from 'src/hooks/useIsConcierge';
import { updateProjectTds } from 'src/Mutation';

const DEFAULT_PAGE_SIZE = 20;
const ALLOWED_STATUS_LIST = [
  'explore',
  'submitted',
  'proposal',
  'approved',
  'procure'
];

export default function SearchContent({ pageSize }) {
  const { activeProjectId } = useSidebarContext();
  const isProjectAdmin = useUserIsInGroup('Project Admin');
  const filters = useRelevantFiltersForForm();
  const quotesFilters = useMemo(() => {
    const filtersCopy = { ...filters };
    if (filters && activeProjectId) {
      filtersCopy.project = {
        id: activeProjectId,
        use_filters: true,
        status: ALLOWED_STATUS_LIST
      };

      // remove all filters that filter the result set because we are filtering on suppliers/
      // tds in the project
      if (filtersCopy.kpis) {
        Object.keys(filtersCopy.kpis).forEach((code) => {
          filtersCopy.kpis[code] = { allow_null: true };
        });
      }
      if (filtersCopy.properties) {
        Object.keys(filtersCopy.properties).forEach((code) => {
          filtersCopy.properties[code] = [];
        });
      }
      if (filtersCopy.form_code__in) {
        filtersCopy.form_code__in = [];
      }
      if (filtersCopy.type_code__in) {
        filtersCopy.type_code__in = [];
      }
      if (filtersCopy.region_code__in) {
        filtersCopy.region_code__in = [];
      }
      filtersCopy.kpi_operator = 'none';
    }
    return filtersCopy;
  }, [filters]);

  const [view, setView] = useState('table');

  // Pagination shouldn't propagate to Explore, so we store in local state only
  const [pagination, setPagination] = useState();

  // Open text search shouldn't propagate to Explore, so we store in local state only
  const [search, setSearch] = useState();

  const [orderBy, setOrderBy] = useState();
  const [tdsBeingEdited, setTdsBeingEdited] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const fields = [
    'tds_id',
    'form',
    'form_code',
    'type',
    'type_code',
    'company_id',
    'company_name',
    'region_code',
    'region',
    'sku',
    'capacity_lbs',
    'supply_capacity_lbs'
  ];

  const agg_fields = ['city', 'state', 'country', 'lat', 'long', 'location_id'];

  const completeFilters = useMemo(
    () => ({
      ...quotesFilters,
      fields,
      agg_fields,
      augment_prices: {},
      page_size: pagination?.pageSize || DEFAULT_PAGE_SIZE,
      page: pagination?.current || 1,
      search,
      ...(orderBy ? { order_by: orderBy } : {})
    }),
    [quotesFilters, search, pagination, orderBy]
  );

  const { data: filteredRecords, isLoading: isLoadingFilteredRecords } =
    useGetWarehouseTdss(completeFilters, {
      refetchOnMount: true
    });
  const queryKey = useGetWarehouseTdssKey(completeFilters);
  const queryClient = useQueryClient();
  const setQueryData = useCallback(
    (fn) => queryClient.setQueryData(queryKey, fn),
    [queryKey]
  );
  const invalidateQueryData = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey
      }),
    [queryKey]
  );
  const onPaginationChange = useCallback(
    (page, pSize) => {
      setPagination({
        current: page,
        pageSize: pSize,
        total: filteredRecords?.count,
        showSizeChanger: false
      });
    },
    [filteredRecords]
  );

  useEffect(() => {
    if (pagination && !pagination.onChange)
      setPagination((v) => ({ ...v, onChange: onPaginationChange }));
  }, [pagination]);

  useEffect(() => {
    setPagination((v) => ({
      current: v?.current || 1,
      pageSize: v?.pageSize || pageSize || DEFAULT_PAGE_SIZE,
      total: filteredRecords?.count,
      showSizeChanger: false
    }));
  }, [filteredRecords]);
  const isConcierge = useIsConcierge();

  const download = () => {
    const allFilters = {
      ...quotesFilters,
      fields,
      agg_fields,
      all: 'true'
    };
    getTdsCsv(allFilters)
      .then((response) => response.text())
      .then((text) => {
        const blob = new Blob([text], { type: 'text/csv;charset=utf-8' });
        saveAs(
          blob,
          `technical-data-sheet-search-${dayjs().format(
            'YYYY-MM-DD-HH-mm' || ''
          )}.csv`
        );
      })
      .catch((error) =>
        messageApi.error(`Error downloading CSV file [${error.message}]`)
      );
  };
  const { data: funnelResults } = useGetProjectFunnel(
    activeProjectId,
    ALLOWED_STATUS_LIST
  );

  const funnel = useMemo(
    () => [
      {
        label: 'Sent',
        description: 'The number of suppliers that have been sent your RFQ.',
        count: funnelResults?.sent || 0
      },
      {
        label: 'Opens',
        count: funnelResults?.opened || 0,
        rateName: 'open rate'
      },
      {
        label: 'Clicked',
        count: funnelResults?.clicked || 0,
        rateName: 'click-through rate'
      },
      {
        label: 'Responses',
        description: 'Suppliers who have responded to our outreach.',
        count: funnelResults?.responded || 0,
        rateName: 'response rate'
      },
      {
        label: 'Quotes',
        description: 'The number of quotes we have received from our outreach.',
        count: funnelResults?.quote || 0,
        rateName: 'completed quotes'
      }
    ],
    [funnelResults]
  );

  const mapMarkers = useMemo(
    () =>
      filteredRecords?.results
        .map((o) => ({
          lat: o.lat,
          long: o.long,
          verified: 1,
          label: o.company_name
        }))
        .flatMap((o) =>
          Array.isArray(o.lat)
            ? o.lat.map((p, i) => ({
                verified: 1,
                lat: p,
                long: o.long[i],
                label: o.label
              }))
            : o
        ),
    [filteredRecords]
  );
  const referenceMarkers = useMemo(
    () =>
      filteredRecords?.meta_data?.project?.locations.map((o) => ({
        label: 'Destination',
        lat: o.latitude,
        long: o.longitude,
        verified: 1
      })),
    [filteredRecords]
  );

  const [showColumnsManager, setShowColumnsManager] = useState();
  const { TableComponent, ColumnsManager, selectedRows } = useTdsTable({
    tdss: filteredRecords?.results || [],
    project: filteredRecords?.meta_data?.project,
    isLoading: isLoadingFilteredRecords,
    setQueryData,
    invalidateQueryData,
    pagination,
    localSort: true
  });

  const areRowsSelected =
    Array.isArray(selectedRows) && selectedRows.length > 0;
  const numRowsSelected =
    (Array.isArray(selectedRows) && selectedRows.length) || 0;
  const selectedText = areRowsSelected
    ? `${numRowsSelected} ${
        numRowsSelected === 1 ? 'suppliers' : 'supplier'
      } selected`
    : 'Select suppliers to continue';

  const { mutateAsync: updateProjectTdsMutation, isLoading: updating } =
    useMutation({
      mutationFn: updateProjectTds,
      onSuccess: () => {
        queryClient.invalidateQueries(['warehouse', 'technical-data-sheet']);
      }
    });

  const navigate = useNavigate();
  const continueToProcure = async () => {
    await Promise.all(
      selectedRows.map(({ project_tds_id }) =>
        updateProjectTdsMutation({
          id: project_tds_id,
          status: 'procure'
        })
      )
    )
      .then(() => {
        navigate(`/project/procure/${activeProjectId}/`);
      })
      .catch((e) => {
        captureException(e);
        messageApi.error('An error ocurred, please try again.', 10);
      });
  };
  return (
    <div className="search-results">
      {contextHolder}
      <Row className="mb-md" wrap={false} align="middle" gutter={8}>
        <Col flex="1">
          <Typography.Title level={4} className="mt-lg mb-sm">
            Manage Quotes
          </Typography.Title>
          <Typography.Text className="inline-block">
            Select suppliers with completed quotes to continue
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Text className="heavy">{selectedText}</Typography.Text>
        </Col>
        <Col>
          <Button
            type="primary"
            disabled={!areRowsSelected || !isProjectAdmin}
            onClick={continueToProcure}
            loading={updating}
          >
            Continue to Procure
          </Button>
        </Col>
      </Row>
      <OutreachResults funnel={funnel} />
      <Row className="search-results--controls" justify="space-between">
        <Col>
          <DebouncedTextInput
            size="small"
            placeholder="Search prospects"
            onChange={(v) => setSearch(v)}
            suffix={<SearchOutlined />}
          />
        </Col>
        <Col>
          <Space direction="horizontal" size={8}>
            <Button disabled={!isConcierge} onClick={download} size="small">
              <DownloadOutlined />
            </Button>
            <Button
              onClick={() => setShowColumnsManager(!showColumnsManager)}
              size="small"
            >
              <SettingFilled /> Columns
            </Button>
            <Radio.Group
              onChange={(e) => setView(e.target.value)}
              value={view}
              size="small"
            >
              <Radio.Button value="table">
                <TableOutlined />
              </Radio.Button>
              <Radio.Button value="map">
                <MapIcon />
              </Radio.Button>
            </Radio.Group>
          </Space>
        </Col>
      </Row>
      {tdsBeingEdited && (
        <EditTdsModal
          open={!!tdsBeingEdited}
          showCompany
          showAdminLinks
          tdsId={tdsBeingEdited}
          hideModal={() => setTdsBeingEdited(null)}
        />
      )}
      {showColumnsManager && ColumnsManager}
      {view === 'table' ? (
        TableComponent
      ) : (
        <MapPageMarkers
          markers={mapMarkers}
          referenceMarkers={referenceMarkers}
        />
      )}
    </div>
  );
}

SearchContent.propTypes = {
  pageSize: PropTypes.number
};
