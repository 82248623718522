import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Modal, Select, Space } from 'antd';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { captureException } from '@sentry/react';

/* eslint-disable import/no-unresolved */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
/* eslint-enable import/no-unresolved */

import { ConditionalContent as ConditionalExploreContent } from 'src/components/project/explore/Content';
import useMetaProperty from 'src/hooks/useMetaProperty';
import { createProject } from 'src/Mutation';

import 'src/pages/project/styles/Explore.less';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { useDebounce } from 'src/hooks/useDebounce';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
);

ChartJS.defaults.font.family = 'Roboto';
ChartJS.defaults.font.size = 12;
ChartJS.defaults.color = '#63748D';
ChartJS.defaults.plugins.title.color = '#323640';
ChartJS.defaults.plugins.title.font.size = 18;
ChartJS.defaults.plugins.title.font.weight = '500';
ChartJS.defaults.plugins.title.align = 'start';
ChartJS.defaults.plugins.title.padding = 0;

export default function ProjectExplore() {
  const navigate = useNavigate();

  const [exploreCtx, setExploreCtx] = useConciergeContextState(['explore']);

  const [searchParams, setSearchParams] = useSearchParams();
  const newProject = searchParams.get('new');

  const [newProjectModalIsOpen, setNewProjectModalIsOpen] =
    useState(newProject);
  useEffect(() => {
    if (newProject) {
      setExploreCtx({ ...exploreCtx, filters: {} });
      setNewProjectModalIsOpen(true);
    }
  }, [newProject]);

  const [form] = Form.useForm();
  const typeProperty = useMetaProperty('type');
  const formProperty = useMetaProperty('form');

  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: createProject,
    onSuccess: (response) => {
      queryClient.invalidateQueries(['project']);
      queryClient.invalidateQueries(['projects']);
      setNewProjectModalIsOpen(false);
      navigate(`/project/explore/${response.uuid}/`);
    },
    onError: (e) => {
      setNewProjectModalIsOpen(false);
      // Send error to Sentry
      captureException(e);
    }
  });

  const { activeProjectId: projectId, activeProject } = useSidebarContext();

  // Populate filters from activeProject
  useEffect(() => {
    if (activeProject) {
      setExploreCtx({
        ...exploreCtx,
        filters: activeProject.filters,
        projectId: activeProject.uuid,
        projectSettings: activeProject.settings || {}
      });
    }
  }, [activeProject, projectId]);

  // Populate filters from URL
  const filtersFromURL = searchParams.get('filters');
  useEffect(() => {
    if (filtersFromURL) {
      const parsedFilters = JSON.parse(filtersFromURL);
      if (Object.keys(parsedFilters).length > 0)
        setExploreCtx({
          ...exploreCtx,
          filters: parsedFilters
        });
    }
  }, []);

  // Store filters in URL for easy sharing
  const debouncedFilters = useDebounce(exploreCtx?.filters, 2000);
  useEffect(() => {
    if (debouncedFilters && Object.keys(debouncedFilters).length > 0) {
      setSearchParams((params) => {
        if (debouncedFilters)
          params.set('filters', JSON.stringify(debouncedFilters));

        return params;
      });
    }
  }, [debouncedFilters]);

  return (
    <div className="explore-page">
      <ConditionalExploreContent />
      <Modal
        width={500}
        title="Create Project"
        open={newProjectModalIsOpen}
        onCancel={() => setNewProjectModalIsOpen(false)}
        okText={isLoading ? 'Creating project...' : 'Create Project'}
        okButtonProps={{ loading: isLoading }}
        onOk={form.submit}
        // eslint-disable-next-line react/no-unstable-nested-components
        footer={(_, { OkBtn }) => (
          <Space direction="vertical" size={12}>
            <OkBtn />
            <span>
              Or{' '}
              <Button
                style={{ padding: 0 }}
                htmlType="button"
                type="link"
                size="small"
                onClick={() => setNewProjectModalIsOpen(false)}
              >
                continue without saving
              </Button>
            </span>
          </Space>
        )}
      >
        <Form
          validateMessages={{
            required: 'This field is required.'
          }}
          layout="vertical"
          form={form}
          disabled={isLoading}
          onFinish={async (values) => {
            await form.validateFields();

            mutateAsync(values);
          }}
        >
          <p>Name your project to save your exploration.</p>
          <Form.Item label="Name this project" name="name">
            <Input />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 40 }}
            label="Pick a material"
            name={['filters', 'type_code__in']}
            rules={[{ required: true }]}
            disabled={!typeProperty}
          >
            <Select
              options={typeProperty?.options?.map((o) => ({
                label: o.value,
                value: o.code
              }))}
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 40 }}
            label="Pick a material form"
            name={['filters', 'form_code__in']}
            rules={[{ required: true }]}
            disabled={!formProperty}
          >
            <Select
              options={[
                {
                  label: 'Resin / Pellets',
                  value: 'resin_pellets'
                },
                {
                  label: 'Bales',
                  value: 'bales'
                },
                {
                  label: 'Flakes',
                  value: 'regrind_flakes'
                }
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
