import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

import { fetch } from './client';

export const handleTokenPair = ({ access, refresh, restricted = 0 }) => {
  window.localStorage.setItem('accessToken', access);
  window.localStorage.setItem('refreshToken', refresh);
  window.localStorage.setItem('restricted', restricted);
};

const clearTokenPair = () => {
  window.console.error('clearTokenPair');
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('refreshToken');
};

export function useSignIn() {
  return useMutation({
    mutationKey: 'signIn',
    mutationFn: async (values) => {
      const headers = {};
      if (values.token) {
        headers.Authorization = `Token ${values.token}`;
      }

      return fetch(`${process.env.REACT_APP_CIRCLE_API}/user/signin/`, {
        method: 'POST',
        headers,
        body: JSON.stringify(values)
      });
    },
    onSuccess: handleTokenPair
  });
}

export function useSignUp() {
  return useMutation({
    mutationKey: 'signUp',
    mutationFn: async (values) =>
      fetch(`${process.env.REACT_APP_CIRCLE_API}/user/signup/`, {
        method: 'POST',
        body: JSON.stringify(values)
      }),

    onSuccess: handleTokenPair
  });
}

export function getTokenPair() {
  const access = window.localStorage.getItem('accessToken');
  const refresh = window.localStorage.getItem('refreshToken');
  return { access, refresh };
}

export function useToken() {
  return getTokenPair();
}

export const useSignOut = (queryClient) => () => {
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('refreshToken');
  queryClient.clear();
};

export function useUser() {
  const { access } = useToken();
  return useQuery({
    queryKey: ['user', access],
    queryFn: async () => {
      const data = await fetch(`${process.env.REACT_APP_CIRCLE_API}/user/me/`);
      if (data instanceof Error) throw new Error(data.statusText);
      if (data?.email) Sentry.setUser({ email: data.email });
      else Sentry.setUser(null);
      return data;
    },
    onError: (e) => {
      window.console.error('no autenticated user', e);
      // this seems extreme
      // clearTokenPair();
    }
  });
}

export function useUserIsInGroup(name) {
  const response = useUser();
  const group = response.data?.groups.find((o) => o.name === name);
  return !!group;
}

export function useAuthentication() {
  const queryClient = useQueryClient();
  const signIn = useSignIn();
  const signUp = useSignUp();
  const signOut = useSignOut(queryClient);

  return {
    signIn,
    signUp,
    signOut,
    token: window.localStorage.getItem('accessToken'),
    isRestricted: window.localStorage.getItem('restricted') === '1'
  };
}
