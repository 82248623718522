import { Button, Row, Space, Table, Tag, Typography } from 'antd';
import React, { useMemo } from 'react';
import { CompassOutlined, SendOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';

import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import dayjs from 'dayjs';
import { MetaPropertyName } from 'src/hooks/useMetaProperty';
import NoWrap from 'src/components/utils/NoWrap';
import { regionMap } from 'src/utils/regions';
import { convertWeightUnitsFromTo, usePreferredUnits } from 'src/utils/units';
import { sortableColumnTitle } from 'src/pages/concierge/ProposalBuilder/columns/usePbColumns';

const DEFAULT_PROJECTS = [];

const projectStagePathMap = {
  explore: 'explore',
  search: 'source',
  procure: 'source',
  manage: 'source'
};

function ProjectDashboard() {
  const { projects = DEFAULT_PROJECTS, isLoadingProjects } =
    useSidebarContext();

  const weightUnits = usePreferredUnits('weight');

  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        title: sortableColumnTitle('Project Name', 'name'),
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (name) => (
          <Typography.Text className="heavy">{name}</Typography.Text>
        )
      },
      {
        title: sortableColumnTitle('Stage', 'stage'),
        dataIndex: 'stage',
        sorter: (a, b) => a.stage.localeCompare(b.stage),
        render: (stage) => <StageName stage={stage} />
      },
      {
        title: sortableColumnTitle('Last Updated', 'updated_at'),
        dataIndex: 'updated_at',
        sorter: (a, b) => dayjs(b.updated_at).diff(dayjs(a.updated_at)),
        render: (isoDate) => (
          <Typography.Text type="secondary">
            {dayjs(isoDate).fromNow()}
          </Typography.Text>
        )
      },
      {
        title: 'Material',
        dataIndex: ['filters', 'type_code__in'],
        render: (type) => (
          <Typography.Text className="thin">
            {type ? (
              <MetaPropertyName property="type" optionValue={type} />
            ) : (
              '--'
            )}
          </Typography.Text>
        )
      },
      {
        title: 'Form',
        dataIndex: ['filters', 'form_code__in'],
        render: (form) => (
          <Typography.Text className="thin">
            {form ? (
              <MetaPropertyName property="form" optionValue={form} />
            ) : (
              '--'
            )}
          </Typography.Text>
        )
      },
      {
        title: 'Region(s)',
        dataIndex: ['filters', 'region_code__in'],
        render: (regionCodes) =>
          regionCodes?.length ? (
            <RegionShortNames regions={regionCodes} />
          ) : (
            '--'
          )
      },
      {
        title: `Qty. (${weightUnits})`,
        dataIndex: ['filters', 'capacity__gte'],
        render: (qtyLbs) => (
          <Typography.Text className="thin">
            {qtyLbs
              ? `${prettyNumberRound(
                  convertWeightUnitsFromTo(qtyLbs, 'lb', weightUnits)
                )} ${weightUnits}`
              : '--'}
          </Typography.Text>
        )
      },
      {
        title: 'Suppliers',
        dataIndex: 'tdss',
        render: (tdss) => tdss?.length || '--'
      }
    ],
    [weightUnits]
  );

  return (
    <div className="project-dashboard m-xxl">
      <div className="project-dashboard-top">
        <Row justify="space-between">
          <Typography.Title level={3}>Dashboard</Typography.Title>
          <Link to="/project/explore/playground/?new=1">
            <Button type="primary" htmlType="button">
              Start Exploring
            </Button>
          </Link>
        </Row>
      </div>
      <div className="project-table">
        <Space direction="vertical" size={16}>
          <Typography.Title level={5}>Projects</Typography.Title>
          <Table
            dataSource={projects}
            loading={isLoadingProjects}
            columns={columns}
            pagination={false}
            rowHoverable
            onRow={(p) => ({
              onClick: () =>
                navigate(`/project/${projectStagePathMap[p.stage]}/${p.uuid}/`) // click row
            })}
          />
        </Space>
      </div>
    </div>
  );
}

export default ProjectDashboard;

const stageNames = {
  explore: (
    <NoWrap>
      <CompassOutlined /> Explore
    </NoWrap>
  ),
  search: (
    <NoWrap>
      <SendOutlined rotate={-45} /> Source
    </NoWrap>
  ),
  procure: (
    <NoWrap>
      <SendOutlined rotate={-45} /> Source
    </NoWrap>
  ),
  manage: (
    <NoWrap>
      <SendOutlined rotate={-45} /> Source
    </NoWrap>
  )
};
function StageName({ stage }) {
  return stageNames[stage];
}
StageName.propTypes = {
  stage: PropTypes.string
};

function RegionShortNames({ regions }) {
  if (!regions) return null;
  return regions.map((region) => (
    <Tag key={region}>{regionMap[region]?.shortName || region}</Tag>
  ));
}
RegionShortNames.propTypes = {
  regions: PropTypes.arrayOf(PropTypes.string)
};
