import React from 'react';
import FigmaEmbed from 'src/components/utils/FigmaEmbed';

function ProjectManage() {
  return (
    <FigmaEmbed figmaUrl="https://www.figma.com/proto/MeYcUXk2uxS2H2KQqQS7Uc/Procure?page-id=2482%3A1815&node-id=2647-19782&node-type=frame&viewport=-1723%2C4832%2C0.43&t=vt3nbcSGH6JSiWy6-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=2647%3A19782&show-proto-sidebar=1" />
  );
}

export default ProjectManage;
