import PropTypes from 'prop-types';
import { Button, Form, Popover, Typography } from 'antd';
import React, { useState } from 'react';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';

export default function ValueInputPopover({
  children,
  ValueComponent,
  value,
  formTitle,
  tds,
  disabled,
  loading,
  onChange,
  extraContent,
  onValuesChange,
  mutate
}) {
  const [open, setOpen] = useState(false);
  const loadingStateClass = useLoadingStateClass(loading);
  const disabledClass = disabled ? 'disabled' : '';
  // Popover needs a clickable child, so we use a div here
  return (
    <Popover
      trigger="click"
      open={open}
      content={
        open ? (
          <>
            {formTitle && (
              <Typography.Text className="heavy pb-sm inline-block">
                {formTitle}
              </Typography.Text>
            )}
            {tds?.project_status === 'explore' && (
              <Typography.Text type="danger" className="mb-sm inline-block">
                <Typography.Text type="danger" strong>
                  You're editing a TDS that was added via explore.
                </Typography.Text>{' '}
                If you wish to add a new TDS, use the dRFQ form.
              </Typography.Text>
            )}
            <ValueInputForm
              ValueComponent={ValueComponent}
              initialValue={value}
              onChange={onChange}
              mutate={mutate}
              extraContent={extraContent}
              onValuesChange={onValuesChange}
              close={() => setOpen(false)}
            />
          </>
        ) : (
          !disabled && <span />
        )
      }
      onOpenChange={(o) => setOpen(o && !disabled)}
      // Necessary so initialValues reload after an update
      destroyTooltipOnHide
    >
      <div
        className={`value-input-popover--container ${loadingStateClass} ${disabledClass}`}
        role="presentation"
      >
        {children}
      </div>
    </Popover>
  );
}
ValueInputPopover.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  tds: PropTypes.object,
  value: PropTypes.any,
  loading: PropTypes.bool,
  formTitle: PropTypes.string,
  disabled: PropTypes.bool,
  mutate: PropTypes.func,
  onChange: PropTypes.func,
  onValuesChange: PropTypes.func,
  extraContent: PropTypes.node,
  ValueComponent: PropTypes.elementType
};

const inputItemKey = 'input';
function ValueInputForm({
  initialValue,
  onChange,
  ValueComponent,
  mutate,
  close,
  onValuesChange,
  extraContent
}) {
  const onFinish = (e) => {
    const value = e[inputItemKey];
    if (onChange) onChange(value);
    if (mutate) mutate(value);
    close();
  };
  const initialValues = {};
  initialValues[inputItemKey] = initialValue;
  // Popover needs a clickable child, so we use a div here
  return (
    <Form
      className="value-input-form"
      initialValues={{ input: initialValue }}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        extra={extraContent}
        className="value-input-form-item"
        name="input"
      >
        <ValueComponent />
      </Form.Item>
      <Button type="primary" size="small" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
}
ValueInputForm.propTypes = {
  initialValue: PropTypes.any,
  onChange: PropTypes.func,
  onValuesChange: PropTypes.func,
  mutate: PropTypes.func,
  close: PropTypes.func,
  extraContent: PropTypes.node,
  ValueComponent: PropTypes.elementType
};
