import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { setTdsMaterialPropertyByCode } from 'src/Mutation';
import ValueInputPopover from 'src/components/project/source/editable-cells/ValueInputPopover';
import { updateResultAttribute } from 'src/components/project/source/editable-cells/helpers';
import { PropertyCompare } from 'src/components/project/explore/supplier-list/BalesTdsRow';
import PropertyMultiInput from 'src/components/form/PropertyMultiInput';
import useMetaProperty from 'src/hooks/useMetaProperty';

export default function TdsPropertyCell({
  tds,
  allTdss,
  disabled,
  propertyCode,
  propertyFilter,
  setQueryData,
  messageApi
}) {
  const value = useMemo(() => {
    const {
      [`property_${propertyCode}_code`]: codesWithNulls = [],
      [`property_${propertyCode}_value`]: valuesWithNulls = []
    } = tds;
    const values = valuesWithNulls.filter((o, i) => codesWithNulls[i]);
    const codes = codesWithNulls.filter((o) => o);
    return { propertyCode, codes, values };
  }, [tds]);
  const property = useMetaProperty(propertyCode);
  const updateLocal = useCallback(
    (v) => {
      setQueryData((o) =>
        updateResultAttribute(
          o,
          ({ tds_id }) => tds_id === tds.tds_id,
          `property_${propertyCode}_code`,
          v.codes
        )
      );
      setQueryData((o) =>
        updateResultAttribute(
          o,
          ({ tds_id }) => tds_id === tds.tds_id,
          `property_${propertyCode}_value`,
          v.values
        )
      );
    },
    [setQueryData, propertyCode, tds]
  );
  const { mutate, isLoading: updating } = useMutation({
    mutationFn: setTdsMaterialPropertyByCode,
    onSuccess: (v) => {},
    onError: () => {
      messageApi.error('Failed to update supplier details');
    }
  });

  const onTdsPropertyChange = (v) => {
    updateLocal(v);
  };

  const tdsPropertyMutate = useCallback(
    (v) => {
      const uuid = tds.tds_id;
      const { codes: properties } = v;
      const body = { uuid, properties, propertyCode };
      mutate(body);
    },
    [propertyCode, tds]
  );

  return (
    <ValueInputPopover
      ValueComponent={PropertyMultiInput}
      disabled={disabled}
      onChange={(v) => onTdsPropertyChange(v)}
      mutate={(v) => tdsPropertyMutate(v)}
      formTitle={property?.name}
      value={value}
      tds={tds}
    >
      <PropertyCompare
        tds={tds}
        propertyCode={propertyCode}
        propertyFilter={propertyFilter}
      />
    </ValueInputPopover>
  );
}
TdsPropertyCell.propTypes = {
  tds: PropTypes.object,
  allTdss: PropTypes.array,
  disabled: PropTypes.bool,
  propertyFilter: PropTypes.object,
  propertyCode: PropTypes.string,
  setQueryData: PropTypes.func,
  messageApi: PropTypes.object
};
