import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from 'src/hooks/useDebounce';

export default function DebouncedTextInput({ onChange, ...inputProps }) {
  const [text, setText] = useState();

  const debouncedText = useDebounce(text, 800);
  useEffect(() => {
    onChange(debouncedText);
  }, [debouncedText]);

  return (
    <Input
      {...inputProps}
      value={text}
      onChange={(e) => e && setText(e.target.value)}
    />
  );
}
DebouncedTextInput.propTypes = {
  onChange: PropTypes.func
};
