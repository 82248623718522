import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

import ExploreSection from 'src/components/project/explore/ExploreSection';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { useMapPageWarehouseSupplier } from 'src/pages/maps/MapPageWarehouseSupplier';
import { Button, Checkbox, Flex, Radio, message } from 'antd';
import {
  CheckCircleFilled,
  DownloadOutlined,
  SearchOutlined,
  TableOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import Visible from 'src/components/layout/Visible';
import MapIcon from 'src/components/icons/MapIcon';
import SearchCircularIcon from 'src/components/icons/SearchCircular';
import Suppliers, { labelMap } from 'src/components/project/explore/Suppliers';
import SelectWithDecorators from 'src/components/form/SelectWithDecorators';
import useIsBalesMode from 'src/components/project/explore/hooks/useIsBalesMode';
import { MetaPropertyName } from 'src/hooks/useMetaProperty';
import usePropertyFilters from 'src/components/project/hooks/usePropertyFilters';
import {
  getTdsListBySupplierByTdsCsv,
  getTdsListBySupplierCsv
} from 'src/Query/warehouse';
import useIsConcierge from 'src/hooks/useIsConcierge';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import DebouncedTextInput from 'src/components/form/DebouncedTextInput';
import Tag from './supplier-list/Tag';
import { useSupplierListFilters } from './hooks/useSupplierList';

const filterStatusOptions = [
  {
    value: 'all',
    label: 'All Suppliers'
  },
  // {
  //   value: 'accepted',
  //   label: 'Accepted Suppliers'
  // },
  {
    value: 'removed',
    label: 'Removed Suppliers'
  }
];

/* eslint-disable react/no-unstable-nested-components */

export default function Location({ locked }) {
  const { SuppliersTab } = useSuppliersTab({ locked });

  return SuppliersTab;
}
Location.propTypes = {
  locked: PropTypes.bool
};

export function useSuppliersTab({ locked }) {
  const [page, setPage] = useState(1);
  const [view, setView] = useState('table');
  const [filterResultValue, setFilterResultValue] = useState('all');
  const [filterMatchValue, setFilterMatchValue] = useState([]);
  const [verifiedOnly, setVerifiedOnly] = useState();
  const [companySearch, setCompanySearch] = useState();
  const balesMode = useIsBalesMode();

  const [filterMatchOptions, filterMatchDetails] =
    useFilterMapOptionsAndDetails();

  const resultIndexFilter = useMemo(
    () => [
      ...new Set(
        filterMatchValue
          .flatMap((v) => filterMatchDetails[v]?.resultIndices)
          .filter((o) => o !== undefined)
      ).keys()
    ],
    [filterMatchValue]
  );

  const parseFilters = useMemo(
    () => (filters, index) => {
      if (!filters || Object.keys(filters).length === 0) return filters;

      const filtersCopy = { ...(filters || {}) };

      const propertiesToFilterOn = filterMatchValue.filter(
        (v) => filterMatchDetails[v]?.isPropertyFilter
      );
      const allPropertyFilters = filters?.kpis_properties?.properties || {};

      if (
        propertiesToFilterOn.length &&
        Object.keys(allPropertyFilters).length
      ) {
        const necessaryProperties = propertiesToFilterOn.reduce(
          (obj, propertyCode) => {
            if (allPropertyFilters[propertyCode]) {
              // eslint-disable-next-line no-param-reassign
              obj[propertyCode] = {
                ...allPropertyFilters[propertyCode],
                or_group: 'necessary'
              };
            }
            return obj;
          },
          {}
        );

        const optionalProperties = Object.keys(allPropertyFilters).reduce(
          (obj, propertyCode) => {
            if (!necessaryProperties[propertyCode]) {
              // eslint-disable-next-line no-param-reassign
              obj[propertyCode] = {
                ...allPropertyFilters[propertyCode],
                or_group: 'optional'
              };
            }

            return obj;
          },
          {}
        );

        const maxMinMatch = Math.min(
          Object.keys(necessaryProperties).length,
          3
        );

        filtersCopy.kpis_properties = {
          properties: {
            ...necessaryProperties,
            ...optionalProperties
          },
          or_groups: {
            necessary: {
              min_match: index >= maxMinMatch ? 1 : maxMinMatch - index
            },
            optional: {
              min_match: 0
            }
          }
        };
      }

      if (verifiedOnly) {
        filtersCopy.is_capability = false;
      }

      return filtersCopy;
    },
    [filterMatchValue, verifiedOnly]
  );

  const { activeProject } = useSidebarContext();
  const projectId = activeProject?.uuid;
  const supplierFilters = useMemo(
    () => ({
      filterResultValue,
      companySearch,
      resultIndexFilter,
      parseFilters,
      projectId,
      page,
      setPage,
      filterSupplierLabels: labelMap.all // By default we want to exclude Removed suppliers
    }),
    [filterResultValue, companySearch, resultIndexFilter, parseFilters, page]
  );
  const { MapComponent } = useMapPageWarehouseSupplier({
    useMapProps: { theme: 'light_new' },
    className: `explore-map ${balesMode ? 'bales' : ''}`,
    supplierFilters
  });

  const [messageApi, contextHolder] = message.useMessage();

  const filterStatusChanged = (v) => {
    setPage(1);
    setFilterResultValue(v);
  };

  const filterMatchChanged = (v) => {
    setPage(1);
    setFilterMatchValue(v);
  };

  const companySearchChanged = (value) => {
    setCompanySearch(value);
    setPage(1);
  };

  const [filters] = useSupplierListFilters(supplierFilters);
  const download = useCallback(() => {
    const companyContactFilters = {
      ...(filters?.filters || {}),
      company_contact: true
    };
    const companyContactExpandedFilters = (filters.expanded_filters || []).map(
      (e) =>
        e && {
          ...e,
          company_contact: true
        }
    );
    const allFilters = {
      ...filters,
      filters: companyContactFilters,
      expanded_filters: companyContactExpandedFilters,
      pagination: { all: true }
    };
    getTdsListBySupplierCsv(allFilters)
      .then((response) => response.text())
      .then((text) => {
        const blob = new Blob([text], { type: 'text/csv;charset=utf-8' });
        saveAs(
          blob,
          `supplier-list-${dayjs().format('YYYY-MM-DD-HH-mm' || '')}.csv`
        );
      })
      .catch((error) =>
        messageApi.error(
          `Error downloading CSV file for supplier-list [${
            error?.message || error
          }]`
        )
      );
    getTdsListBySupplierByTdsCsv(allFilters)
      .then((response) => response.text())
      .then((text) => {
        const blob = new Blob([text], { type: 'text/csv;charset=utf-8' });
        saveAs(
          blob,
          `supplier-tds-list-${dayjs().format('YYYY-MM-DD-HH-mm' || '')}.csv`
        );
      })
      .catch((error) =>
        messageApi.error(
          `Error downloading CSV file for supplier-tds-list [${
            error?.message || error
          }]`
        )
      );
  }, [filters]);

  const isConcierge = useIsConcierge();
  // this is a bit hacky to get the radio buttons and download button to look consistent
  const radioButtonStyle = { height: '100%', paddingTop: '3px' };

  return {
    filters,
    SuppliersTab: (
      <>
        {contextHolder}
        <Visible lazy visible={balesMode}>
          <ExplorePanel className="mb-xl" style={{ height: 500 }}>
            {MapComponent}
          </ExplorePanel>
        </Visible>
        <Flex
          className="explore-suppliers--controls my-sm"
          justify="space-between"
        >
          <Flex gap={8} direction="horizontal">
            <DebouncedTextInput
              allowClear
              size="small"
              placeholder="Search for supplier"
              onChange={companySearchChanged}
              suffix={<SearchOutlined />}
            />
            <SelectWithDecorators
              style={{
                minWidth: 180,
                height: 32
              }}
              Icon={CheckCircleFilled}
              onChange={filterStatusChanged}
              value={filterResultValue}
              options={filterStatusOptions}
            />
            <SelectWithDecorators
              style={{
                minWidth: 280,
                height: 32
              }}
              mode="multiple"
              Icon={SearchCircularIcon}
              onChange={filterMatchChanged}
              value={filterMatchValue}
              placeholder="Specifications"
              labelRender={(v) => (
                <Tag
                  text={filterMatchDetails[v.value]?.label}
                  type={filterMatchDetails[v.value]?.type}
                  highlight={filterMatchDetails[v.value]?.highlight}
                />
              )}
              optionRender={(option) => (
                <Tag
                  text={filterMatchDetails[option.value]?.label}
                  type={filterMatchDetails[option.value]?.type}
                  highlight={filterMatchDetails[option.value]?.highlight}
                />
              )}
              options={filterMatchOptions}
            />
            {balesMode && (
              <Checkbox
                checked={verifiedOnly}
                onChange={(e) => setVerifiedOnly(e.target.checked)}
                size="small"
              >
                Show verified suppliers only
              </Checkbox>
            )}
          </Flex>

          <Flex gap={8} direction="horizontal">
            <Button disabled={!isConcierge} onClick={download} size="small">
              <DownloadOutlined />
            </Button>
            {!balesMode && (
              <Radio.Group
                onChange={(e) => setView(e.target.value)}
                value={view}
                size="small"
              >
                <Radio.Button value="table" style={radioButtonStyle}>
                  <TableOutlined />
                </Radio.Button>
                <Radio.Button value="map" style={radioButtonStyle}>
                  <MapIcon />
                </Radio.Button>
              </Radio.Group>
            )}
          </Flex>
        </Flex>
        <Visible lazy visible={view === 'map'}>
          <ExplorePanel className="my-lg" style={{ height: 500 }}>
            {MapComponent}
          </ExplorePanel>
        </Visible>

        <Visible visible={view === 'table'}>
          <ExploreSection>
            <Suppliers locked={locked} supplierFilters={supplierFilters} />
          </ExploreSection>
        </Visible>
      </>
    )
  };
}

const resinFilterMatchOptions = [
  {
    value: 'primaryFull',
    label: 'Primary Full'
  },
  {
    value: 'primaryPartial',
    label: 'Primary Partial'
  },
  {
    value: 'secondaryFull',
    label: 'Secondary Full'
  },
  {
    value: 'secondaryPartial',
    label: 'Secondary Partial'
  }
];

const resinFilterMatchDetails = {
  primaryFull: {
    label: 'Primary',
    type: 'normal',
    highlight: 'high',
    resultIndices: [0, 1, 2]
  },
  primaryPartial: {
    label: 'Primary',
    type: 'normal',
    highlight: 'low',
    resultIndices: [3, 4, 5]
  },
  secondaryFull: {
    label: 'Secondary',
    type: 'normal',
    highlight: 'high',
    resultIndices: [0, 3, 6]
  },
  secondaryPartial: {
    label: 'Secondary',
    type: 'normal',
    highlight: 'low',
    resultIndices: [1, 4, 7]
  }
};

const EMPTY_VALUE = {};

function useFilterMapOptionsAndDetails() {
  const isBales = useIsBalesMode();
  const properties = usePropertyFilters() || EMPTY_VALUE;

  if (!isBales) return [resinFilterMatchOptions, resinFilterMatchDetails];

  const propertyCodes = Object.keys(properties);

  const balesFilterMatchOptions = propertyCodes.map((code) => ({
    label: <MetaPropertyName property={code} />,
    value: code
  }));

  const balesFilterMatchDetails = propertyCodes.reduce((obj, code) => {
    // eslint-disable-next-line no-param-reassign
    obj[code] = {
      label: <MetaPropertyName property={code} />,
      type: 'normal',
      highlight: 'high',
      isPropertyFilter: true
    };

    return obj;
  }, {});

  return [balesFilterMatchOptions, balesFilterMatchDetails];
}
