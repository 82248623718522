import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Button, message } from 'antd';
import { Route, Routes, useParams } from 'react-router-dom';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import FiltersSidebar from 'src/components/project/FiltersSidebar';
import ProjectExplore from 'src/pages/project/ProjectExplore';
import SidebarCascade from 'src/components/layout/sidebar/SidebarCascade';
import dayjs from 'dayjs';
import { useMutation } from '@tanstack/react-query';
import { updateProject } from 'src/Mutation';
import { captureException } from '@sentry/react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { CloudUploadOutlined } from '@ant-design/icons';
import ManageProjectEllipsis from 'src/components/project/ManageProjectEllipsis';
// import ProjectQuotes from 'src/pages/project/ProjectQuotes';

export default function ExploreRoutes() {
  return (
    <Routes>
      <Route path="/:projectId/*" element={<ExploreSubroutes />} />
    </Routes>
  );
}

function ExploreSubroutes() {
  const { setActiveProjectId, activeProject, refetchProjects } =
    useSidebarContext();
  const { projectId: projectIdOrPlayground } = useParams();
  const projectId =
    projectIdOrPlayground === 'playground' ? undefined : projectIdOrPlayground;

  const [exploreCtx] = useConciergeContextState(['explore']);
  const [unsavedChanges, setUnsavedChanges] = useState();
  useEffect(() => {
    if (exploreCtx?.filters && activeProject?.filters) {
      setUnsavedChanges(
        JSON.stringify(exploreCtx.filters) !==
          JSON.stringify(activeProject.filters)
      );
    } else {
      setUnsavedChanges(false);
    }
  }, [exploreCtx, activeProject]);

  const [messageApi, contextHolder] = message.useMessage();
  const { mutate, isLoading } = useMutation({
    mutationFn: updateProject,
    onSuccess: (p) => {
      messageApi.open({
        type: 'success',
        content: 'Successfully saved project.'
      });
      setUnsavedChanges(false);
      refetchProjects();
    },
    onError: (e) => {
      // Send error to Sentry
      captureException(e);
      messageApi.open({
        type: 'error',
        content: 'An error ocurred. Please try again.'
      });
    }
  });

  useEffect(() => {
    setActiveProjectId(projectId);
  }, [projectId]);

  const drfqSent = ['search', 'procure', 'manage'].includes(
    activeProject?.stage
  );

  return (
    <div className="project-explore">
      {contextHolder}
      <Row wrap={false} className="individually-scrolling-cols">
        <Col className="project-explore--sidebar">
          <SidebarCascade
            style={{
              minWidth: 324,
              maxWidth: 324
            }}
            light
            title={
              activeProject ? (
                <>
                  <Row
                    wrap={false}
                    align="top"
                    gutter={[8, 0]}
                    justify="space-between"
                  >
                    <Col style={{ marginRight: 'auto' }}>
                      <Typography.Text className="xl heavy">
                        {activeProject?.name}
                      </Typography.Text>
                    </Col>
                    {!drfqSent && (
                      <Col>
                        <Button
                          size="small"
                          htmlType="button"
                          loading={isLoading}
                          disabled={!unsavedChanges}
                          onClick={() =>
                            mutate({
                              uuid: activeProject.uuid,
                              filters: exploreCtx?.filters,
                              settings: {
                                ...activeProject.settings,
                                ...exploreCtx?.projectSettings
                              }
                            })
                          }
                        >
                          {unsavedChanges ? 'Save' : 'Saved'}
                        </Button>
                      </Col>
                    )}
                    <Col>
                      <ManageProjectEllipsis />
                    </Col>
                  </Row>
                  <Row>
                    {activeProject?.updated_at ? (
                      <Typography.Paragraph
                        className="font-size-2 mt-xxs mb-0"
                        type="secondary"
                      >
                        {!unsavedChanges || drfqSent ? (
                          <>
                            Last Updated:{' '}
                            {dayjs(activeProject?.updated_at).fromNow()}
                          </>
                        ) : (
                          <>
                            <CloudUploadOutlined /> Unsaved changes
                          </>
                        )}
                      </Typography.Paragraph>
                    ) : null}
                  </Row>
                </>
              ) : (
                'Explore'
              )
            }
          >
            <FiltersSidebar />
          </SidebarCascade>
        </Col>
        <Col className="project-explore--content">
          <ProjectExplore />
        </Col>
      </Row>
    </div>
  );
}
