import React, { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

import { PriceCompare } from 'src/components/project/explore/supplier-list/TdsRow';
import { convertWeightUnitsFromTo } from 'src/utils/units';
import { updateProjectTds } from 'src/Mutation';
import ValueInputPopover from 'src/components/project/source/editable-cells/ValueInputPopover';
import {
  updateResultAttribute,
  valueWithCurrencyUnits
} from 'src/components/project/source/editable-cells/helpers';
import { SelectableCurrencyPerWeightUnitsInput } from 'src/components/form/SelectableCurrencyUnitsInput';

export default function ProjectPriceCell({
  tds,
  allTdss,
  disabled,
  project,
  fieldName,
  setQueryData,
  messageApi
}) {
  const { mutate: updateProjectTdsMutation, isLoading: updating } = useMutation(
    {
      mutationFn: updateProjectTds,
      onSuccess: (res) => {
        ['freight', 'tariff', 'fee'].forEach((priceField) => {
          const newVal = res[priceField];
          // We update the converted value using what was returned from server
          setQueryData((o) =>
            updateResultAttribute(
              o,
              ({ tds_id }) => tds_id === tds.tds_id,
              `project_${priceField}`,
              newVal
            )
          );
        });
      },
      onError: () => {
        messageApi.error('Failed to update supplier details');
      }
    }
  );

  // We update the original, non-converted converted value using what we have in frontend
  const onPerWeightChange = useCallback(
    (v) => {
      const attribute = `project_${fieldName}_original_currency`;
      const value = convertWeightUnitsFromTo(
        parseFloat(v?.value),
        tds?.project_weight_units, // inverted
        v?.units
      );
      setQueryData((o) =>
        updateResultAttribute(
          o,
          ({ tds_id }) => tds_id === tds.tds_id,
          attribute,
          value
        )
      );
      setQueryData((o) =>
        updateResultAttribute(
          o,
          ({ tds_id }) => tds_id === tds.tds_id,
          'project_original_currency',
          v.currency
        )
      );
    },
    [tds, fieldName]
  );

  const projectTdsPerWeightMutate = useCallback(
    (v) => {
      const attribute = `${fieldName}_per_lb`;
      const precision = 1e-8;
      const value =
        Math.round(
          (1 / precision) *
            convertWeightUnitsFromTo(
              parseFloat(v?.value),
              'lb', // inverted
              v?.units
            )
        ) * precision;
      const body = { id: tds.project_tds_id };
      body[attribute] = value.toFixed(8);
      body.currency = v.currency;
      updateProjectTdsMutation(body);
    },
    [allTdss, tds, fieldName]
  );

  const [changedCurrency, setChangedCurrency] = useState();

  return (
    <ValueInputPopover
      ValueComponent={SelectableCurrencyPerWeightUnitsInput}
      onChange={(v) => onPerWeightChange(v)}
      disabled={disabled}
      mutate={(v) => projectTdsPerWeightMutate(v)}
      onValuesChange={({ input: { currency } }) => {
        setChangedCurrency(currency !== tds.project_original_currency);
      }}
      extraContent={
        changedCurrency && (
          <div>
            <Typography.Text type="warning">
              Updating currency for this TDS's freight, fee, and tariff fields.
            </Typography.Text>
          </div>
        )
      }
      value={valueWithCurrencyUnits(
        tds?.[`project_${fieldName}_original_currency`],
        tds?.project_original_currency,
        tds?.project_weight_units
      )}
    >
      <PriceCompare
        value={tds?.[`project_${fieldName}`]}
        target={project[`target_${fieldName}`]}
        currency={project.currency}
        units={project.price_weight_units}
      />
    </ValueInputPopover>
  );
}
ProjectPriceCell.propTypes = {
  tds: PropTypes.object,
  disabled: PropTypes.bool,
  allTdss: PropTypes.array,
  project: PropTypes.object,
  fieldName: PropTypes.string,
  setQueryData: PropTypes.func,
  messageApi: PropTypes.object
};
