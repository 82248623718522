import { Dropdown, message, Popconfirm } from 'antd';
import React, { useState, useMemo } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { captureException } from '@sentry/react';
import { DeleteOutlined } from '@ant-design/icons';

import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { deleteProject } from 'src/Mutation';
import ProjectSettings from 'src/pages/project/ProjectSettings';
import useIsConcierge from 'src/hooks/useIsConcierge';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import MoreIcon from 'src/components/icons/More';

export default function ManageProjectEllipsis() {
  const [_, setExploreCtx] = useConciergeContextState(['explore']);

  const navigate = useNavigate();
  const isConcierge = useIsConcierge();

  const [messageApi, contextHolder] = message.useMessage();

  const queryClient = useQueryClient();

  const { activeProject: project } = useSidebarContext();
  const projectId = project?.uuid;

  const location = useLocation();
  const { mutate: deleteProjectMutation } = useMutation({
    mutationFn: deleteProject,
    onSuccess: () => {
      queryClient.invalidateQueries(['project']);
      queryClient.invalidateQueries(['projects']);

      navigate(location.pathname);

      setExploreCtx({ filters: {}, projectSettings: {} });

      messageApi.open({
        type: 'success',
        content: 'Successfully deleted project.'
      });
    },
    onError: (e) => {
      navigate(location.pathname);
      // Send error to Sentry
      captureException(e);
    }
  });

  const [editingProject, setEditingProject] = useState();

  const renameProjectHandler = () => {
    setEditingProject(true);
  };
  const deleteProjectHandler = () => {
    deleteProjectMutation(projectId);
  };

  const projectMenuItems = useMemo(
    () => [
      ...(isConcierge
        ? [
            {
              key: 'drfq',
              label: (
                <Link to={`/digital-rfq/${projectId}`} target="_blank">
                  <button className="bare" type="button">
                    Digital RFQ
                  </button>
                </Link>
              )
            },
            {
              key: 'django',
              label: (
                <Link
                  to={`${process.env.REACT_APP_CIRCLE_API}/admin/api/project/${projectId}/`}
                  target="_blank"
                >
                  <button className="bare" type="button">
                    View in Django
                  </button>
                </Link>
              )
            },
            { type: 'divider' }
          ]
        : []),
      {
        key: '1',
        label: (
          <button
            onClick={() => renameProjectHandler()}
            className="bare"
            type="button"
          >
            Rename
          </button>
        )
      },
      {
        key: '2',
        label: (
          <Popconfirm
            title="Delete project"
            description="Are you sure you want to delete this project?"
            onConfirm={deleteProjectHandler}
            okText="Delete"
            cancelText="Cancel"
            okType="danger"
          >
            <button
              // Dont' close dropdown
              onClick={(e) => e.stopPropagation()}
              className="bare"
              type="button"
            >
              <DeleteOutlined /> Delete Project
            </button>
          </Popconfirm>
        ),
        danger: true
      }
    ],
    [isConcierge, projectId]
  );

  return projectId ? (
    <>
      {contextHolder}
      <Dropdown
        trigger="click"
        menu={{ items: projectMenuItems }}
        placement="bottomLeft"
      >
        <button type="button" className="bare manage-project-ellipsis">
          <MoreIcon />
        </button>
      </Dropdown>
      <ProjectSettings
        open={editingProject}
        close={() => setEditingProject(false)}
        uuid={projectId}
        callback={(response) => navigate(`/project/explore/${response.uuid}/`)}
      />
    </>
  ) : null;
}
