const formTypeRegionKeys = [
  'form_code__in',
  'type_code__in',
  'region_code__in'
];

function filterFilters(filters, keysToKeep) {
  return Object.keys(filters)
    .filter((key) => keysToKeep.includes(key))
    .reduce((filteredObj, key) => {
      filteredObj[key] = filters[key]; // eslint-disable-line no-param-reassign
      return filteredObj;
    }, {});
}

export function filterToFormTypeRegionOnly(filters) {
  return filterFilters(filters, formTypeRegionKeys);
}
