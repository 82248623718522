import { useQuery } from '@tanstack/react-query';

import { getProjectFunnel } from 'src/Query';

export default function useGetProjectFunnel(uuid, statusList) {
  return useQuery({
    queryKey: ['project/funnel', uuid, statusList],
    queryFn: () => getProjectFunnel(uuid, statusList),
    enabled: !!uuid
  });
}
