import { SearchOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useTdsTable } from 'src/components/project/source/TdsTable';
import DebouncedTextInput from 'src/components/form/DebouncedTextInput';
import useProcureTdss from 'src/components/project/procure/useProcureTdss';

export default function ProcureOverview() {
  // Open text search shouldn't propagate to Explore, so we store in local state only
  const [search, setSearch] = useState();

  const {
    data: filteredRecords,
    isLoading: isLoadingFilteredRecords,
    queryKey
  } = useProcureTdss();

  const queryClient = useQueryClient();
  const setQueryData = useCallback(
    (fn) => queryClient.setQueryData(queryKey, fn),
    [queryKey]
  );
  const invalidateQueryData = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey
      }),
    [queryKey]
  );

  const { TableComponent } = useTdsTable({
    tdss: filteredRecords?.results || [],
    isLoading: isLoadingFilteredRecords,
    filterColumns: ['company_name', 'price.price', 'total_cost'],
    setQueryData,
    invalidateQueryData,
    localSort: true
  });

  return (
    <div className="search-results">
      <Row className="mb-md" wrap={false} align="middle" gutter={8}>
        <Col flex="1">
          <Typography.Title level={4} className="mt-lg mb-sm">
            <Typography.Text type="secondary">Procure / </Typography.Text>
            Overview
          </Typography.Title>
        </Col>
      </Row>
      <Row className="search-results--controls" justify="space-between">
        <Col>
          <DebouncedTextInput
            size="small"
            placeholder="Search prospects"
            onChange={(v) => setSearch(v)}
            suffix={<SearchOutlined />}
          />
        </Col>
      </Row>
      {TableComponent}
    </div>
  );
}
