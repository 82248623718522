import { useMemo } from 'react';
import { useVisibility } from 'src/components/layout/Visible';
import { getSupplierGeoAnalytics } from 'src/Query/warehouse';
import useDebouncedQuery from 'src/hooks/useDebouncedQuery';
import useExpandedFiltersForSupplierList from './useExpandedFilters';

const DEFAULT_PARAMS = {};

export default function useSupplierGeoAnalytics({
  zoomLevel,
  companySearch,
  filterSupplierLabels,
  resultIndexFilter,
  parseFilters
} = DEFAULT_PARAMS) {
  const { filterList } = useExpandedFiltersForSupplierList();

  const parsedFilterList = useMemo(
    () =>
      typeof parseFilters === 'function'
        ? filterList.map((filtersSet, i) => parseFilters(filtersSet, i))
        : filterList,
    [parseFilters, filterList]
  );

  const [filters, ...expandedFilters] = parsedFilterList;

  const payload = useMemo(
    () => ({
      filters,
      expanded_filters: expandedFilters,
      level: zoomLevel,
      view_settings: {
        filter_supplier_labels: filterSupplierLabels,
        search_company: companySearch,
        result_index__in: resultIndexFilter
      }
    }),
    [filters, expandedFilters, zoomLevel]
  );
  const visible = useVisibility();
  const { data, isFetching: isLoading } = useDebouncedQuery({
    queryKey: ['warehouse', 'tds-supplier-list-geo-analytics', payload],
    queryFn: () => getSupplierGeoAnalytics(payload),
    keepPreviousData: true,
    enabled: visible,
    debounce: 0 // We already debounce in MapPageWarehouseSupplier ?
  });
  return { data, isLoading };
}
