import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';

export default function FigmaEmbed({ figmaUrl, title }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 4000);
  }, []);

  // const { pushCascade, popCascade } = useSidebarContext();

  // useEffect(() => {
  //   pushCascade(undefined, 'portfolio', 0);
  //   return () => popCascade('portfolio');
  // }, []);
  return (
    <>
      {loading && (
        <Skeleton
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100vh',
            background: 'white'
          }}
        />
      )}
      <iframe
        title={title}
        style={{
          width: '100%',
          height: '100vh'
        }}
        src={`https://www.figma.com/embed?${new URLSearchParams({
          embed_host: 'share',
          'hide-ui': 1,
          'show-proto-sidebar': 0,
          'hotspot-hints': 0,
          scaling: 'scale-down-width',
          url: figmaUrl
        }).toString()}`}
      />
    </>
  );
}
FigmaEmbed.propTypes = {
  figmaUrl: PropTypes.string.isRequired,
  title: PropTypes.string
};
