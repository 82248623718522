import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { KpiRangeCompare } from 'src/components/project/explore/supplier-list/TdsRow';
import { createDeleteOrUpdateTdsNumericalPropertyValue } from 'src/Mutation';
import ValueInputPopover from 'src/components/project/source/editable-cells/ValueInputPopover';
import { updateResultAttribute } from 'src/components/project/source/editable-cells/helpers';
import {
  KpiRangeInputContext,
  MultipleKpiRangeInput
} from 'src/components/form/KpiRangeInput';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';

export default function TdsKpiCell({
  tds,
  kpiCode,
  disabled,
  kpiFilter,
  setQueryData,
  messageApi
}) {
  const property = useMetaNumericalProperty(kpiCode);
  const value = useMemo(
    () =>
      (tds[`kpi_${kpiCode}.breakdown`] || []).map((bd) => ({
        ...bd,
        kpiCode,
        property_id: property?.uuid
      })),
    [tds, kpiCode, property]
  );
  const { mutateAsync, isLoading: updating } = useMutation({
    mutationFn: createDeleteOrUpdateTdsNumericalPropertyValue,
    // onSuccess: (res, passedValues) => {}, Handiling this in Promise.all()
    onError: () => {
      messageApi.error('Failed to update supplier details');
    }
  });

  const tdsKpiMutate = useCallback(
    (values) => {
      // Updae all and then update query data
      Promise.all(
        values.map((v) =>
          mutateAsync({
            ...v,
            tds_id: tds.tds_id,
            id: v.link_id
          })
        )
      ).then((updatedVals) => {
        // filter out empty responses, eg from DELETEs
        const filteredValues = updatedVals.filter(({ uuid }) => !!uuid);
        const v = {
          min: null,
          max: null,
          linkIds: [],
          units: null
        };

        if (filteredValues.length) {
          v.min = Math.min(...filteredValues.map(({ min }) => min));
          v.max = Math.max(...filteredValues.map(({ max }) => max));
          v.linkIds = filteredValues.map(({ uuid }) => uuid);
          v.units = filteredValues[0].units;
        }

        setQueryData((o) =>
          updateResultAttribute(
            o,
            ({ tds_id }) => tds_id === tds.tds_id,
            `kpi_${kpiCode}.min`,
            v.min || null
          )
        );
        setQueryData((o) =>
          updateResultAttribute(
            o,
            ({ tds_id }) => tds_id === tds.tds_id,
            `kpi_${kpiCode}.max`,
            v.max || null
          )
        );
        setQueryData((o) =>
          updateResultAttribute(
            o,
            ({ tds_id }) => tds_id === tds.tds_id,
            `kpi_${kpiCode}.link_id`,
            v.linkIds
          )
        );
        setQueryData((o) =>
          updateResultAttribute(
            o,
            ({ tds_id }) => tds_id === tds.tds_id,
            `kpi_${kpiCode}.units`,
            v.units || null
          )
        );

        const newBreakdown = filteredValues.map((val) => ({
          ...val,
          link_id: val.uuid
        }));

        setQueryData((o) =>
          updateResultAttribute(
            o,
            ({ tds_id }) => tds_id === tds.tds_id,
            `kpi_${kpiCode}.breakdown`,
            newBreakdown
          )
        );
      });
    },
    [kpiCode, tds]
  );

  return (
    <KpiRangeInputContext.Provider value={property}>
      <ValueInputPopover
        ValueComponent={MultipleKpiRangeInput}
        // onChange={(v) => onTdsKpiChange(v)}
        disabled={disabled}
        mutate={(v) => tdsKpiMutate(v)}
        formTitle={property?.name}
        value={value}
        loading={updating}
        tds={tds}
      >
        <KpiRangeCompare kpiCode={kpiCode} tds={tds} kpiFilter={kpiFilter} />
      </ValueInputPopover>
    </KpiRangeInputContext.Provider>
  );
}
TdsKpiCell.propTypes = {
  tds: PropTypes.object,
  kpiFilter: PropTypes.object,
  disabled: PropTypes.bool,
  kpiCode: PropTypes.string,
  setQueryData: PropTypes.func,
  messageApi: PropTypes.object
};
