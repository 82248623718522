import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Flex, Input } from 'antd';
import { ArrowLeftOutlined, RightOutlined } from '@ant-design/icons';
import CheckedPrimary from 'src/components/icons/CheckedPrimary';
import CirclePlain from 'src/components/icons/CirclePlain';

export const SidebarMenuContext = createContext();

export default function SidebarCascade({
  title,
  items,
  children,
  selectItem,
  allowSearch,
  light,
  back,
  ...props
}) {
  const [search, setSearch] = useState();
  return (
    <div
      className={`project-sidebar-cascade ${light ? 'light' : ''}`}
      {...props}
    >
      {back && (
        <div className="project-sidebar-cascade--back pb-xs">
          <button className="bare" type="button" onClick={back.action}>
            <ArrowLeftOutlined /> {back.label}
          </button>
        </div>
      )}
      <div className="project-sidebar-cascade--title pb-sm">
        {typeof title === 'string' ? (
          <Typography.Text className="xl heavy">{title}</Typography.Text>
        ) : (
          title
        )}
      </div>
      {allowSearch && (
        <div className="project-sidebar-cascade--search pt-xs">
          <Input
            size="small"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search projects"
          />
        </div>
      )}
      {items && (
        <div className="project-sidebar-cascade--items">
          {items
            .filter(
              (item) => !allowSearch || !search || item.label.includes(search)
            )
            .map((item, index) => (
              <SidebarItem
                selected={item.active}
                key={item.key}
                label={item.label}
                icon={item.icon}
                component={item.component}
                next={item.next}
                onSelect={() => selectItem(item, index)}
              />
            ))}
        </div>
      )}
      {children}
    </div>
  );
}
SidebarCascade.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  items: PropTypes.array,
  children: PropTypes.node,
  selectItem: PropTypes.func,
  allowSearch: PropTypes.bool,
  light: PropTypes.bool,
  back: PropTypes.object
};

function SidebarItem({ label, next, component, onSelect, selected, icon }) {
  return (
    <div
      className={`project-sidebar-item ${selected ? 'selected' : ''}`}
      role="presentation"
      onClick={onSelect}
    >
      <Flex justify="space-between" gap={8}>
        {component || (
          <Typography.Text ellipsis={{ tooltip: label }}>
            {icon ? <>{icon}&nbsp;&nbsp;</> : undefined}
            {label}
          </Typography.Text>
        )}
        {next ? <RightOutlined /> : undefined}
      </Flex>
    </div>
  );
}
SidebarItem.propTypes = {
  label: PropTypes.string,
  next: PropTypes.bool,
  selected: PropTypes.bool,
  component: PropTypes.node,
  icon: PropTypes.node,
  onSelect: PropTypes.func
};

export function ProjectStep({ step }) {
  return (
    <div className="project-sidebar-step">
      {step.done ? <CheckedPrimary /> : <CirclePlain />}
      &nbsp;
      <Typography.Text ellipsis={{ tooltip: step.label }}>
        {step.label}
      </Typography.Text>
    </div>
  );
}
ProjectStep.propTypes = {
  step: PropTypes.object
};
