import PropTypes from 'prop-types';
import { Col, message, Row, Table, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { sentenceCase } from 'change-case';

import { sortableColumnTitle } from 'src/pages/concierge/ProposalBuilder/columns/usePbColumns';
import { Box } from 'src/components/project/explore/summary/helpers';
import { MetaPropertyName } from 'src/hooks/useMetaProperty';
import {
  kpiMid,
  PriceCompare
} from 'src/components/project/explore/supplier-list/TdsRow';
import { currencyValuePrice } from 'src/utils/currency';
import numericRange from 'src/components/utils/numericRange';
import { capacityValueToLabel } from 'src/components/project/explore/filters/CapacityFilter';
import { LogPricingModalTrigger } from 'src/components/supplier/LogPricingModal';
import { primaryKpisByMaterial } from 'src/components/project/hooks/usePrimaryKpis';
import useColumnManager from 'src/components/project/source/useColumnManager';
import { KpiName } from 'src/components/project/explore/filters/KpiFilter';
import falsyNot0 from 'src/components/utils/falsyNot0';
import { EditableFunnelStageTag } from 'src/components/project/source/FunnelStageTag';
import ChatIcon from 'src/components/icons/Chat';
import { useIntercom } from 'react-use-intercom';
import { FieldTimeOutlined, PaperClipOutlined } from '@ant-design/icons';
import { useQuoteDetails } from 'src/components/project/source/QuoteDetails';
import ProjectPriceCell from 'src/components/project/source/editable-cells/ProjectPriceCell';
import WeightCell from 'src/components/project/source/editable-cells/WeightCell';
import { useUserIsInGroup } from 'src/utils/authentication';
import TdsKpiCell from './editable-cells/TdsKpiCell';
import TdsPropertyCell from './editable-cells/TdsPropertyCell';
import ProjectLocationCell from './editable-cells/ProjectLocationCell';

export default function TdsTable({
  tdss,
  project,
  isLoading,
  localSort,
  setQueryData,
  invalidateQueryData,
  pagination
}) {
  const { TableComponent } = useTdsTable({
    tdss,
    project,
    isLoading,
    localSort,
    setQueryData,
    invalidateQueryData,
    pagination
  });

  return TableComponent;
}
TdsTable.propTypes = {
  tdss: PropTypes.array,
  isLoading: PropTypes.bool,
  localSort: PropTypes.bool,
  project: PropTypes.object,
  setQueryData: PropTypes.func,
  invalidateQueryData: PropTypes.func,
  pagination: PropTypes.object
};

const EMPTY_PROJECT = {};

export function useTdsTable({
  tdss,
  project,
  isLoading,
  filterColumns,
  pagination,
  setQueryData,
  invalidateQueryData,
  localSort
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [showingTdsId, setShowingTdsId] = useState();
  const [messageApi, contextHolder] = message.useMessage();

  const { QuoteDetails, setFilter } = useQuoteDetails({
    open: !!showingTdsId,
    onClose: () => setShowingTdsId(null),
    tds: tdss.find(({ tds_id }) => tds_id === showingTdsId)
  });

  const {
    Checkboxes: ColumnsManager,
    updatedColumns,
    originalColumns
  } = useTdsTableColumns({
    tdss,
    project,
    localSort,
    onRowExpand: (tds, hasAttachment) => {
      setShowingTdsId(tds.tds_id);
      setFilter(hasAttachment ? 'attachments' : 'all');
    },
    setQueryData,
    invalidateQueryData,
    messageApi
  });

  useEffect(() => {
    setSelectedRows([]);
  }, [tdss]);

  const finalColumns = useMemo(
    () =>
      filterColumns
        ? originalColumns.filter(({ dataIndex }) =>
            filterColumns.includes(dataIndex)
          )
        : updatedColumns,
    [originalColumns, updatedColumns, filterColumns]
  );

  const rfqRow = useMemo(
    () =>
      project ? (
        <Table.Summary fixed="top">
          <Table.Summary.Row>
            {/* Placeholder for checkbox column */}
            <Table.Summary.Cell index={0}></Table.Summary.Cell>
            {finalColumns.map((c, i) => {
              let renderedValue = null;
              try {
                const value = Array.isArray(c.dataIndex)
                  ? c.dataIndex.reduce(
                      (currentValue, currentIndex) =>
                        currentValue[currentIndex],
                      project
                    )
                  : project[c.dataIndex];

                renderedValue = value;
                if (c.rfqRender) renderedValue = c.rfqRender(value, project);
                else if (c.render) renderedValue = c.render(value, project);
              } catch (_) {
                renderedValue = '-';
              }
              return (
                <Table.Summary.Cell
                  key={
                    c.key ||
                    (Array.isArray(c.dataIndex)
                      ? c.dataIndex.join('.')
                      : c.dataIndex)
                  }
                  index={i + 1}
                  colSpan={c.hidden ? 0 : 1}
                >
                  <Typography.Text strong>{renderedValue}</Typography.Text>
                </Table.Summary.Cell>
              );
            })}
          </Table.Summary.Row>
        </Table.Summary>
      ) : null,
    [finalColumns, project]
  );

  const TableComponent = useMemo(
    () => (
      <>
        {contextHolder}
        <Table
          className="search-results--table tds-list"
          rowKey="id"
          loading={isLoading}
          summary={!project ? undefined : () => rfqRow}
          columns={finalColumns}
          dataSource={tdss || []}
          rowSelection={{
            type: 'checkbox',
            onChange: (_, newSelectedRows) => setSelectedRows(newSelectedRows),
            preserveSelectedRowKeys: true,
            selectedRowKeys: selectedRows.map(({ id }) => id)
          }}
          scroll={{
            scrollToFirstRowOnChange: true,
            x: 'scroll'
          }}
          sticky
          pagination={pagination}
        />
        {QuoteDetails}
      </>
    ),
    [isLoading, project, tdss, selectedRows]
  );

  return {
    TableComponent,
    ColumnsManager,
    selectedRows
  };
}

function totalCost(tdsObj) {
  return (
    (tdsObj.project_tariff || 0) +
      (tdsObj.project_fee || 0) +
      (tdsObj.project_freight || 0) +
      (tdsObj.price?.price || 0) || null
  );
}

function totalCostTarget(tdsObj) {
  return (
    (tdsObj.target_tariff || 0) +
      (tdsObj.target_fee || 0) +
      (tdsObj.target_freight || 0) +
      (tdsObj.price || 0) || null
  );
}

function valueWithCurrencyUnits(value, currency, units) {
  return {
    value,
    currency,
    units
  };
}

function updateResultArrayAttribute(resultArray, index, attribute, value) {
  const array = [...resultArray];
  array[index] = { ...resultArray[index] };
  array[index][attribute] = value;
  return array;
}

function updateResultAttribute(result, index, attribute, value) {
  if (!result) return result;
  if (Array.isArray(result.results)) {
    return {
      ...result,
      results: updateResultArrayAttribute(
        result.results,
        index,
        attribute,
        value
      )
    };
  }
  if (Array.isArray(result)) {
    return updateResultArrayAttribute(result, index, attribute, value);
  }
  return result;
}

function useTdsTableColumns({
  tdss,
  project = EMPTY_PROJECT,
  localSort,
  onRowExpand,
  setQueryData,
  invalidateQueryData,
  messageApi
}) {
  const firstTds = tdss?.[0];
  const isProjectAdmin = useUserIsInGroup('Project Admin');
  const isTdsEditor = useUserIsInGroup('TDS Editor');
  const { filters } = project;

  const kpiColumns = useMemo(() => {
    const kpiCodes = new Set(
      firstTds
        ? Object.keys(firstTds)
            .filter((tdsAttr) => tdsAttr.startsWith('kpi_'))
            .map((tdsAttr) => {
              const match = tdsAttr.match(/kpi_(.+?)\./);
              return match[1];
            })
        : []
    );
    const primaryKpis =
      (firstTds && primaryKpisByMaterial[firstTds.type_code]) ||
      primaryKpisByMaterial.DEFAULT;

    return [...kpiCodes]
      .sort((a) => (primaryKpis.includes(a) ? -1 : 0))
      .map((kpiCode) => {
        const kpiKey = `kpi_${kpiCode}`;

        return {
          title: sortableColumnTitle(
            <KpiName codeOrUuid={kpiCode} />,
            `${kpiKey}`
          ),
          sorter:
            !localSort || ((a, b) => kpiMid(kpiCode, a) - kpiMid(kpiCode, b)),
          dataIndex: `${kpiKey}.code`,
          sortKey: `${kpiKey}`,
          key: kpiCode,
          width: 120,
          render: (_, tdsObj, index) => {
            const kpiFilter = filters?.kpis?.[kpiCode];

            return (
              <TdsKpiCell
                tds={tdsObj}
                disabled={!isTdsEditor}
                allTdss={tdss}
                setQueryData={setQueryData}
                kpiFilter={kpiFilter}
                messageApi={messageApi}
                kpiCode={kpiCode}
              />
            );
          },
          rfqRender: (
            _,
            {
              filters: {
                kpis: {
                  [kpiCode]: { min, max, units }
                }
              }
            }
          ) =>
            numericRange({
              min,
              max,
              empty: '--',
              rangeRender: (rangeMin, rangeMax) =>
                `${rangeMin} - ${rangeMax} ${units || ''}`,
              noMaxRender: (v) => `> ${v} ${units || ''}`,
              noMinRender: (v) => `< ${v} ${units || ''}`,
              singleValueRender: (v) => `${v} ${units || ''}`
            })
        };
      });
  }, [tdss, setQueryData]);

  const propertyColumns = useMemo(() => {
    const propertyCodes = new Set(
      firstTds
        ? Object.keys(firstTds)
            .filter((tdsAttr) => tdsAttr.startsWith('property_'))
            .map((tdsAttr) => {
              const match = tdsAttr.match(/^property_(.*)_[^_]+$/);
              return match[1];
            })
        : []
    );

    // for now we sort to a fixed order - ultimately need to make this configurable
    const sortOrder = {
      color: 1,
      bale_grade: 2,
      bale_contamination: 3,
      bale_form: 4,
      bale_source: 5
    };
    const sortedPropertyCodes = [...propertyCodes].sort(
      (a, b) => (sortOrder[a] || 1e8) - (sortOrder[b] || 1e8)
    );

    return sortedPropertyCodes.map((propertyCode) => {
      const propertyKey = `property_${propertyCode}`;
      const propertyFilter = filters?.properties?.[propertyCode];

      return {
        title: <MetaPropertyName property={propertyCode} />,
        sorter:
          !localSort ||
          ((a, b) =>
            a[`property_${propertyCode}_code`]
              .join('')
              .localeCompare(b[`property_${propertyCode}_code`].join(''))),
        dataIndex: `${propertyKey}.code`,
        sortKey: `${propertyKey}`,
        key: propertyCode,
        width: 130,
        render: (_, tdsObj, index) => (
          <TdsPropertyCell
            tds={tdsObj}
            allTdss={tdss}
            disabled={!isTdsEditor}
            setQueryData={setQueryData}
            propertyFilter={propertyFilter}
            messageApi={messageApi}
            propertyCode={propertyCode}
          />
        ),
        rfqRender: (
          _,
          {
            filters: {
              properties: { [propertyCode]: propertyValues }
            }
          }
        ) =>
          propertyValues.map((value, i) => (
            <React.Fragment key={value}>
              <MetaPropertyName property={propertyCode} optionValue={value} />
              {i + 1 < propertyValues.length ? ', ' : ''}
            </React.Fragment>
          ))
      };
    });
  }, [tdss, setQueryData]);

  const priceColumns = useMemo(
    () => [
      {
        title: sortableColumnTitle('Material Price', `price.price`),
        dataIndex: `price.price`,
        sorter: (a, b) => (a.price?.price || 0) - (b.price?.price || 0),
        sortKey: 'price',
        key: 'price',
        width: 120,
        render: (_, tdsObj) => (
          <LogPricingModalTrigger
            tdsLocationId={tdsObj.project_tds_location_id}
            disabled={!isTdsEditor}
          >
            <PriceCompare
              value={tdsObj?.price?.price}
              target={project.price}
              currency={project.currency}
              units={project.price_weight_units}
            />
          </LogPricingModalTrigger>
        ),
        rfqRender: (_, { price, currency, price_weight_units }) =>
          falsyNot0(price)
            ? '--'
            : `${currencyValuePrice(price, currency, price_weight_units)}`
      }
      // {
      //   title: 'Terms',
      //   sorter: true,
      //   dataIndex: `price.terms`,
      //   sortKey: 'terms',
      //   key: 'terms',
      //   width: 120,
      //   render: (_, tdsObj) => (
      //     <ValueCompare label={tdsObj?.price?.terms} type="neutral" />
      //   ),
      //   rfqRender: () => ''
      // }
    ],
    [tdss, project, setQueryData]
  );

  const projectColumns = useMemo(
    () => [
      {
        title: sortableColumnTitle('Freight', `project_freight`),
        dataIndex: `project_freight`,
        sorter:
          !localSort ||
          ((a, b) => (a.project_freight || 0) - (b.project_freight || 0)),
        key: 'project_freight',
        width: 120,
        render: (_, tdsObj, index) => (
          <ProjectPriceCell
            tds={tdsObj}
            disabled={!isProjectAdmin}
            allTdss={tdss}
            project={project}
            fieldName="freight"
            setQueryData={setQueryData}
            messageApi={messageApi}
          />
        ),
        rfqRender: (_, { target_freight, currency, price_weight_units }) =>
          falsyNot0(target_freight)
            ? '--'
            : `${currencyValuePrice(
                target_freight,
                currency,
                price_weight_units
              )}`
      },
      {
        title: sortableColumnTitle('Tariffs', 'project_tariff'),
        dataIndex: `project_tariff`,
        sorter:
          !localSort ||
          ((a, b) => (a.project_tariff || 0) - (b.project_tariff || 0)),
        key: 'project_tariff',
        width: 120,
        render: (_, tdsObj, index) => (
          <ProjectPriceCell
            tds={tdsObj}
            allTdss={tdss}
            disabled={!isProjectAdmin}
            project={project}
            fieldName="tariff"
            setQueryData={setQueryData}
            messageApi={messageApi}
          />
        ),
        rfqRender: (_, { target_tariff, currency, price_weight_units }) =>
          falsyNot0(target_tariff)
            ? '--'
            : `${currencyValuePrice(
                target_tariff,
                currency,
                price_weight_units
              )}`
      },
      {
        title: sortableColumnTitle('Fees', 'project_fee'),
        dataIndex: `project_fee`,
        sorter:
          !localSort || ((a, b) => (a.project_fee || 0) - (b.project_fee || 0)),
        key: 'project_fee',
        width: 120,
        render: (_, tdsObj, index) => (
          <ProjectPriceCell
            tds={tdsObj}
            allTdss={tdss}
            disabled={!isProjectAdmin}
            project={project}
            fieldName="fee"
            setQueryData={setQueryData}
            messageApi={messageApi}
          />
        ),
        rfqRender: (_, { target_fee, currency, price_weight_units }) =>
          `${currencyValuePrice(target_fee, currency, price_weight_units)}`
      },
      {
        title: sortableColumnTitle('Total cost', 'total_cost'),
        dataIndex: `total_cost`,
        sortKey: 'total_cost',
        sorter: !localSort || ((a, b) => totalCost(a) - totalCost(b)),
        key: 'total_cost',
        width: 120,
        render: (_, tdsObj) => (
          <PriceCompare
            value={totalCost(tdsObj)}
            target={totalCostTarget(project)}
            currency={project.currency}
            units={project.price_weight_units}
          />
        ),
        rfqRender: (_, { currency, price_weight_units, ...rfq }) =>
          !totalCostTarget(rfq)
            ? '--'
            : `${currencyValuePrice(
                totalCostTarget(rfq),
                currency,
                price_weight_units
              )}`
      }
    ],
    [tdss, project, setQueryData]
  );

  const countryList = useMemo(
    () => [...new Set(((localSort && tdss) || []).flatMap((t) => t.country))],
    [tdss]
  );

  const funnelStageList = useMemo(
    () => [
      ...new Set(
        ((localSort && tdss) || []).flatMap((t) => t.project_funnel_stage)
      )
    ],
    [tdss]
  );

  const { showConversation, show } = useIntercom();

  const allColumns = useMemo(
    () => [
      {
        title: sortableColumnTitle('Supplier name', 'company_name', {
          style: { whiteSpace: 'nowrap' }
        }),
        sorter:
          !localSort ||
          ((a, b) => a.company_name.localeCompare(b.company_name)),
        filters:
          localSort &&
          countryList.map((c) => ({
            text: c,
            value: c
          })),
        onFilter: (value, record) => record.country.some((a) => a === value),
        sortKey: 'company_name',
        dataIndex: 'company_name',
        fixed: 'left',
        width: 280,
        render: (companyName, tdsObj, index) => (
          <Row
            justify="space-between"
            align="middle"
            wrap={false}
            gutter={[16, 16]}
          >
            <Col>
              <span className="company">
                <a
                  href={`/supplier/${tdsObj.company_id}`}
                  title={`${tdsObj.company_name} profile`}
                  target="_blank"
                >
                  {companyName}
                </a>
                {tdsObj.sku ? (
                  <Typography.Text italic type="secondary">
                    {' '}
                    ({tdsObj.sku})
                  </Typography.Text>
                ) : null}
              </span>
              <ProjectLocationCell
                tds={tdsObj}
                disabled={!isTdsEditor || !isProjectAdmin}
                allTdss={tdss}
                fieldName="location"
                setQueryData={setQueryData}
                invalidateQueryData={invalidateQueryData}
                messageApi={messageApi}
              />
            </Col>
          </Row>
        ),
        rfqRender: () => ''
      },
      ...(project
        ? [
            {
              title: '',
              hiddenTitle: 'Attachments',
              sorter: false,
              filters: false,
              onFilter: null,
              dataIndex: 'project_attachment',
              fixed: 'left',
              width: 30,
              render: (attachmentId, tdsObj) =>
                attachmentId ? (
                  <Col>
                    <button
                      className="bare"
                      type="button"
                      onClick={
                        onRowExpand ? () => onRowExpand(tdsObj, true) : null
                      }
                    >
                      <PaperClipOutlined />
                    </button>
                  </Col>
                ) : null,
              rfqRender: () => ''
            },
            {
              title: sortableColumnTitle('Status', 'project_funnel_stage', {
                style: { whiteSpace: 'nowrap' }
              }),
              sorter:
                !localSort ||
                ((a, b) =>
                  a.project_funnel_stage.localeCompare(b.project_funnel_stage)),
              filters:
                localSort &&
                funnelStageList.map((c) => ({
                  text: sentenceCase(c || ''),
                  value: c
                })),
              onFilter: (value, record) =>
                record.project_funnel_stage === value,
              sortKey: 'project_funnel_stage',
              dataIndex: 'project_funnel_stage',
              fixed: 'left',
              width: 90,
              render: (projectFunnelStage, tdsObj) => (
                <EditableFunnelStageTag
                  projectTdsId={tdsObj.project_tds_id}
                  stage={projectFunnelStage}
                />
              ),
              rfqRender: () => ''
            },
            {
              title: '',
              hiddenTitle: 'Activity',
              sorter: false,
              filters: false,
              onFilter: null,
              dataIndex: 'project_intercom_conversation_id',
              fixed: 'left',
              width: 70,
              render: (intercomConversationId, tdsObj) => (
                <Row gutter={[8, 8]} className="actions">
                  <Col>
                    <button
                      className="bare"
                      disabled
                      type="button"
                      onClick={
                        () => {}
                        /* (intercomConversationId &&
                          showConversation(intercomConversationId)) ||
                        show() */
                      }
                    >
                      <ChatIcon />
                    </button>
                  </Col>
                  {onRowExpand ? (
                    <Col>
                      <button
                        className="bare"
                        type="button"
                        onClick={() => onRowExpand(tdsObj)}
                      >
                        <FieldTimeOutlined />
                      </button>
                    </Col>
                  ) : null}
                </Row>
              ),
              rfqRender: () => ''
            }
          ]
        : []),
      {
        title: sortableColumnTitle('Type', 'type'),
        sorter: true,
        sortKey: 'type_code',
        width: 120,
        dataIndex: 'type',
        render: (val) => <Box>{val}</Box>,
        rfqRender: (_, { filters: { type_code__in } }) => (
          <Typography.Text
            ellipsis={{ tooltip: true }}
            className="heavy font-size-2"
          >
            <MetaPropertyName property="type" optionValue={type_code__in} />
          </Typography.Text>
        )
      },
      {
        title: sortableColumnTitle('Form', 'form'),
        sorter: true,
        sortKey: 'form_code',
        width: 120,
        dataIndex: 'form',
        render: (val) => <Box>{val}</Box>,
        rfqRender: (_, { filters: { form_code__in } }) => (
          <Typography.Text
            ellipsis={{ tooltip: true }}
            className="heavy font-size-2"
          >
            <MetaPropertyName property="form" optionValue={form_code__in} />
          </Typography.Text>
        )
      },
      {
        title: sortableColumnTitle('Quantity', 'capacity'),
        sorter:
          !localSort ||
          ((a, b) =>
            (a.capacity || a.supply_capacity || 0) -
            (b.capacity || b.supply_capacity || 0)),
        dataIndex: 'capacity',
        width: 120,
        render: (_, tdsObj, index) => (
          <WeightCell
            disabled={!isTdsEditor}
            tds={tdsObj}
            fieldName="capacity"
            target={filters?.capacity__gte}
            setQueryData={setQueryData}
            messageApi={messageApi}
          />
        ),
        rfqRender: (_, { filters: { capacity__gte } }) =>
          capacityValueToLabel(capacity__gte)
      },
      ...priceColumns,
      ...projectColumns,
      ...kpiColumns,
      ...propertyColumns
    ],
    [
      kpiColumns,
      propertyColumns,
      priceColumns,
      project,
      tdss,
      setQueryData,
      invalidateQueryData,
      messageApi
    ]
  );

  return useColumnManager(allColumns);
}
