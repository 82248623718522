import { Button, Col, Row, Space, Typography } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import ProjectSettings from 'src/pages/project/ProjectSettings';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';

export default function ProjectHeader({ children }) {
  const navigate = useNavigate();

  const { activeProject: project } = useSidebarContext();
  const projectId = project?.uuid;

  const [editingProject, setEditingProject] = useState();

  return (
    <>
      <Row justify="space-between" align="bottom" className="project-header">
        <Col>
          <Typography.Title style={{ marginTop: 0 }} level={3}>
            Explore Results
          </Typography.Title>
        </Col>
        <Col>
          <Space direction="horizontal" size={12}>
            {children}
            {projectId ? null : (
              <Button htmlType="button" onClick={() => setEditingProject(true)}>
                Save As New Project
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <ProjectSettings
        open={editingProject}
        close={() => setEditingProject(false)}
        uuid={projectId}
        callback={(response) => navigate(`/project/explore/${response.uuid}/`)}
      />
    </>
  );
}
ProjectHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
