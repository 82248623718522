import React, { useEffect, useMemo, useState } from 'react';
import PropType from 'prop-types';
import { Group } from '@visx/group';
import { scaleLinear, scaleSqrt } from '@visx/scale';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { format } from '@visx/vendor/d3-format';
import { LegendLinear } from '@visx/legend';
import { Popover, Tag } from 'antd';
import './Heatmap.less';
import useMetaPropertyOptionNameFromCode from 'src/hooks/useMetaPropertyOptionNameFromCode';
import useMetaNumericalPropertyNameFromCode from 'src/hooks/useMetaNumericalPropertyNameFromCode';
import prettyNumber from 'src/components/utils/prettyNumber';
import AdminConsoleLink from 'src/components/navigate/AdminConsoleLink';
import prettyId from 'src/components/utils/prettyId';
import useIsConcierge from 'src/hooks/useIsConcierge';

const zeroDecimalFormat = (n) =>
  n < 1 && n > 0 ? format('.1f')(n) : format('.0f')(n);

const layerColors = [
  ['#cbcbcb', '#292929'],
  ['#d6e0f8', '#275fe7'],
  ['#f7bde0', '#ec1e9a'],
  ['#c9f2d8', '#24a206'],
  ['#f5e1c5', '#e99824'],
  ['#f0c7f3', '#e227f1'],
  ['#e5e2c1', '#b7ab16']
];

const emptyColor = '#f0f0f0';

// const defaultMargin = { top: 10, left: 20, right: 20, bottom: 110 };
const defaultMargin = { top: 30, left: 70, right: 20, bottom: 60 };

function TdsList({ uuids }) {
  return (
    <ol className="list-tds-unstyled">
      {(uuids || []).map((uuid) => (
        <li key={uuid}>
          <AdminConsoleLink app="api" type="technicaldatasheet" uuid={uuid}>
            TDS #{prettyId(uuid)}
          </AdminConsoleLink>
        </li>
      ))}
    </ol>
  );
}

TdsList.propTypes = {
  uuids: PropType.arrayOf(PropType.string)
};

function limitRange(v, min, max) {
  if (v < min) return min;
  if (v > max) return max;
  return v;
}

function Heatmap({
  width,
  height,
  data,
  kpiX,
  kpiY,
  targetX,
  onTargetXChange,
  targetY,
  onTargetYChange,
  unitsX,
  unitsY,
  events = false,
  margin = defaultMargin
}) {
  const [layerActive, setLayerActive] = useState([]);
  const [activeCellValue, setActiveCellValue] = useState();

  const isConcierge = useIsConcierge();

  useEffect(
    () => data && setLayerActive(new Array(data.length).fill(true)),
    [data]
  );

  // the order of the layers from the API is the footprint, so that smaller
  // layers are on top
  // but to show deterministic order for colors and legend
  // we calculate a new order based on the properrty information of the layer:
  //      primary sort order is the property type and secondary is the property value
  //
  // layerOrder is a mapping from the index of the layer in `data` to its new order
  // inverseLayerOrder is a mapping from the new order back to the index of `data`

  const [layerOrder, inverseLayerOrder] = useMemo(() => {
    if (Array.isArray(data)) {
      const indexes = [...Array(data.length).keys()]; // [0, 1, ... n-1]
      const layerKeys = data.map(
        (layer) => `${layer.layer_property || ''}/${layer.label}`
      );
      indexes.sort((ia, ib) => layerKeys[ia].localeCompare(layerKeys[ib]));
      return [
        indexes,
        indexes.map((_, i) => indexes.findIndex((v) => v === i)) // reverse the map
      ];
    }
    return [[], []];
  }, [data]);

  const layerColorScales = useMemo(() => {
    if (Array.isArray(data)) {
      return data.map((layer, index) => {
        const colors = layerColors[inverseLayerOrder[index]] || layerColors[0];
        return scaleSqrt({
          range: [colors[0], colors[1]],
          domain: [0, Math.max(...layer.heatmap.flat())]
        });
      });
    }
    return [];
  }, [data]);

  const emptyCell = { color: emptyColor, value: 0, label: '' };
  const mergedHeatmap = useMemo(() => {
    if (data) {
      const baseHeatmap = data[0].heatmap;
      const layers = data.length;
      const cols = baseHeatmap[0].length;
      const rows = baseHeatmap.length;
      const heatmap = Array.from({ length: rows }, () =>
        Array(cols).fill(emptyCell)
      );
      for (let r = 0; r < rows; r++) {
        for (let c = 0; c < cols; c++) {
          for (let l = layers - 1; l >= 0; l--) {
            if (layerActive[l]) {
              const layer = data[l];
              const cell = layer.heatmap[r][c];
              const uuids = layer.uuids ? layer.uuids?.[r]?.[c] : [];
              if (cell) {
                heatmap[r][c] = {
                  color: layerColorScales[l](cell),
                  value: cell,
                  label: layer.label,
                  total: layer.tds_count,
                  uuids: uuids || []
                };
                break;
              }
            }
          }
        }
      }
      return heatmap;
    }
    return undefined;
  }, [data, layerActive]);

  const layerNames = useMetaPropertyOptionNameFromCode(
    data?.map((layer) => layer.label)
  );

  const kpiNames = useMetaNumericalPropertyNameFromCode([kpiX, kpiY]);

  const layerNamesWithBase = [...(layerNames || ['Base'])];
  layerNamesWithBase[0] = 'Base';

  if (!data) return undefined;

  const baseLayer = data ? data[0] : { heatmap: [] };
  const { heatmap } = baseLayer;
  const cellsX = data ? heatmap[0].length : 0;
  const cellsY = data ? heatmap.length : 0;
  const minX = baseLayer.min_x;
  const minY = baseLayer.min_y;
  const maxX = baseLayer.max_x;
  const maxY = baseLayer.max_y;

  // bounds

  const xMaxPixel = width - margin.left - margin.right;
  const yMaxPixel = height - margin.bottom - margin.top;

  const cellWidth = xMaxPixel / cellsX;
  const cellHeight = yMaxPixel / cellsY;

  // scales for cell index to pixel position
  const xScale = scaleLinear({
    domain: [0, cellsX],
    range: [0, xMaxPixel]
  });
  const yScale = scaleLinear({
    domain: [0, cellsY],
    range: [yMaxPixel - cellHeight, -cellHeight]
  });

  // scales for pixel position to cell index
  const xScaleInverse = scaleLinear({
    range: [0, cellsX],
    domain: [0, xMaxPixel]
  });
  const yScaleInverse = scaleLinear({
    range: [0, cellsY],
    domain: [yMaxPixel - cellHeight, -cellHeight]
  });

  // scales for physical units to pixel position
  const xScaleUnits = scaleLinear({
    domain: [minX, maxX],
    range: [0, xMaxPixel]
  });
  const yScaleUnits = scaleLinear({
    domain: [minY, maxY],
    range: [yMaxPixel, 0]
  });

  // scales for pixel position to physical units
  const xScaleUnitsInverse = scaleLinear({
    domain: [0, xMaxPixel],
    range: [minX, maxX]
  });
  const yScaleUnitsInverse = scaleLinear({
    domain: [yMaxPixel, 0],
    range: [minY, maxY]
  });

  // scales for cell index to physical units
  const xScaleUnitsCell = scaleLinear({
    domain: [0, cellsX],
    range: [minX, maxX]
  });
  const yScaleUnitsCell = scaleLinear({
    domain: [0, cellsY],
    range: [minY, maxY]
  });

  const pickTargetXY = (targetArray, index, scale) =>
    Array.isArray(targetArray) && Number.isFinite(targetArray[index])
      ? scale(targetArray[index])
      : null;

  const targetXmin = pickTargetXY(targetX, 0, xScaleUnits);
  const targetXmax = pickTargetXY(targetX, 1, xScaleUnits);
  const targetYmin = pickTargetXY(targetY, 0, yScaleUnits);
  const targetYmax = pickTargetXY(targetY, 1, yScaleUnits);

  const gap = 2;

  const mouseMove = (e) => {
    try {
      const { offsetX, offsetY } = e.nativeEvent;
      const cellX = Math.trunc(xScaleInverse(offsetX - margin.left));
      const cellY = Math.ceil(yScaleInverse(offsetY - margin.top));
      const value = mergedHeatmap[cellY]?.[cellX];
      if (value?.value)
        setActiveCellValue({
          ...value,
          valueY: yScaleUnitsCell(cellY),
          valueX: xScaleUnitsCell(cellX)
        });
      else if (cellX >= 0 && cellY >= 0)
        setActiveCellValue({
          value: 0,
          valueY: yScaleUnitsCell(cellY),
          valueX: xScaleUnitsCell(cellX)
        });
      else setActiveCellValue(undefined);
    } catch (ex) {
      window.console.log('mouseMove error', ex, e, mergedHeatmap);
    }
  };

  const mouseLeave = () => {
    setActiveCellValue(undefined);
  };
  const showShaded = !!(
    (targetXmin || targetXmax) &&
    (targetYmin || targetYmax)
  );
  const shadedXmin = targetXmin
    ? limitRange(targetXmin, 1, targetXmax || xMaxPixel)
    : 1;
  const shadedXmax = targetXmax
    ? limitRange(targetXmax, targetXmin || 1, xMaxPixel)
    : xMaxPixel;
  const shadedYmin = targetYmax
    ? limitRange(targetYmax, 1, targetYmin || yMaxPixel)
    : 1;
  const shadedYmax = targetYmin
    ? limitRange(targetYmin, targetYmax || 1, yMaxPixel)
    : yMaxPixel;
  return width < 10 ? null : (
    <div
      className="heatmap"
      style={{ width: width + margin.left + margin.right + 50 }}
    >
      <svg
        width={width}
        height={height}
        onMouseMove={mouseMove}
        onMouseLeave={mouseLeave}
      >
        <Group top={margin.top} left={margin.left}>
          <AxisLeft
            scale={yScaleUnits}
            top={0}
            left={0}
            label={`${kpiNames[1]} (${unitsY})`}
            strokeWidth={0}
            numTicks={8}
            stroke="#1b1a1e"
            tickTextFill="#1b1a1e"
            labelClassName="left-horiz"
          />
          <AxisBottom
            scale={xScaleUnits}
            top={yMaxPixel}
            label={`${kpiNames[0]} (${unitsX})`}
            strokeWidth={0}
            numTicks={12}
            stroke="#1b1a1e"
            tickTextFill="#1b1a1e"
          />

          {mergedHeatmap.map((row, rowIndex) =>
            row.map((cell, cellIndex) => {
              const cellElement = (
                <rect
                  className="visx-heatmap-rect"
                  width={cellWidth - gap}
                  height={cellHeight - gap}
                  x={xScale(cellIndex)}
                  y={yScale(rowIndex)}
                  fill={cell.color}
                  onClick={() => {
                    if (!events) return;
                    // eslint-disable-next-line
                    alert(JSON.stringify({ rowIndex, cellIndex, cell }));
                  }}
                />
              );
              return (
                <React.Fragment key={`heatmap-rect-${rowIndex}-${cellIndex}`}>
                  {!isConcierge && cellElement}
                  {(isConcierge && cell.uuids?.length && (
                    <Popover
                      placement="right"
                      trigger="click"
                      content={<TdsList uuids={cell.uuids} />}
                    >
                      {cellElement}
                    </Popover>
                  )) ||
                    cellElement}
                </React.Fragment>
              );
            })
          )}
          {targetXmin && (
            <InteractiveLine
              type="vertical"
              // We set min position to 1 instead of 0 bc 0's don't get rendered
              position={limitRange(targetXmin, 1, targetXmax || xMaxPixel)}
              positionLimit={(p) => limitRange(p, 1, targetXmax || xMaxPixel)}
              xMax={xMaxPixel}
              yMax={yMaxPixel}
              onChange={(pixelPosition) =>
                onTargetXChange([xScaleUnitsInverse(pixelPosition), targetX[1]])
              }
            />
          )}
          {targetXmax && (
            <InteractiveLine
              type="vertical"
              // We set min position to 1 instead of 0 bc 0's don't get rendered
              position={limitRange(targetXmax, targetXmin || 1, xMaxPixel)}
              positionLimit={(p) => limitRange(p, targetXmin || 1, xMaxPixel)}
              xMax={xMaxPixel}
              yMax={yMaxPixel}
              onChange={(pixelPosition) =>
                onTargetXChange([targetX[0], xScaleUnitsInverse(pixelPosition)])
              }
            />
          )}
          {targetYmin && (
            <InteractiveLine
              type="horizontal"
              // In Y axis, yMaxPixel is bottom border and 0 is top border
              // We set min position to 1 instead of 0 bc 0's don't get rendered
              position={limitRange(targetYmin, targetYmax || 1, yMaxPixel)}
              positionLimit={(p) => limitRange(p, targetYmax || 1, yMaxPixel)}
              xMax={xMaxPixel}
              yMax={yMaxPixel}
              onChange={(pixelPosition) =>
                onTargetYChange([yScaleUnitsInverse(pixelPosition), targetY[1]])
              }
            />
          )}
          {targetYmax && (
            <InteractiveLine
              type="horizontal"
              // In Y axis, yMaxPixel is bottom border and 0 is top border
              // We set min position to 1 instead of 0 bc 0's don't get rendered
              position={limitRange(targetYmax, 1, targetYmin || yMaxPixel)}
              positionLimit={(p) => limitRange(p, 1, targetYmin || yMaxPixel)}
              xMax={xMaxPixel}
              yMax={yMaxPixel}
              onChange={(pixelPosition) =>
                onTargetYChange([targetY[0], yScaleUnitsInverse(pixelPosition)])
              }
            />
          )}
          {showShaded && (
            <ShadedBox
              xMin={shadedXmin}
              yMin={shadedYmin}
              xMax={shadedXmax}
              yMax={shadedYmax}
            />
          )}
        </Group>
      </svg>
      <div className="details">
        <LegendLinear
          scale={layerColorScales[0]}
          labelFormat={(d, i) => (i % 2 === 0 ? zeroDecimalFormat(d) : '')}
          direction="column-reverse"
          itemDirection="row-reverse"
          labelMargin="0 20px 0 0"
          shapeMargin="1px 0 0"
        />
        <div className="cell-details">
          {activeCellValue ? (
            <>
              <div>
                TDS count:{' '}
                <strong>{zeroDecimalFormat(activeCellValue.value)}</strong>
              </div>
              <div style={{ marginTop: 8 }}>
                {kpiX}: {prettyNumber(activeCellValue.valueX)}&nbsp;{unitsX}
              </div>
              <div>
                {kpiY}: {prettyNumber(activeCellValue.valueY)}&nbsp;{unitsY}
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className="heatmap-tags">
        {layerOrder.map((orderedIndex, index) => {
          // const originalIndex = inverseLayerOrder[orderedIndex];
          const o = data[orderedIndex];
          const name = layerNamesWithBase[orderedIndex];
          return (
            <Tag.CheckableTag
              key={o.label}
              style={{
                background:
                  layerActive[orderedIndex] && layerColors[index]
                    ? layerColors[index][1]
                    : '#eeeeee'
              }}
              checked={layerActive[orderedIndex]}
              onChange={(checked) => {
                const newValue = [...layerActive];
                newValue[orderedIndex] = checked;
                setLayerActive(newValue);
              }}
            >
              {`${name} (${o.tds_count || 0})`}
            </Tag.CheckableTag>
          );
        })}
      </div>
    </div>
  );
}
Heatmap.propTypes = {
  width: PropType.number,
  height: PropType.number,
  targetX: PropType.array,
  targetY: PropType.array,
  onTargetXChange: PropType.func,
  onTargetYChange: PropType.func,
  data: PropType.array,
  margin: PropType.object,
  kpiX: PropType.string,
  kpiY: PropType.string,
  unitsX: PropType.string,
  unitsY: PropType.string,
  events: PropType.bool
};

export default Heatmap;

function InteractiveLine({
  type,
  position: passedPosition,
  xMax,
  yMax,
  positionLimit,
  onChange
}) {
  const [position, setPosition] = useState(passedPosition);

  // Update line position if value changes directly (eg via sidebar)
  useEffect(() => {
    if (position !== passedPosition) {
      setPosition(passedPosition);
    }
  }, [passedPosition]);

  const isVertical = type === 'vertical'; // We assume horizontal otherwise
  const cursorPositionKey = isVertical ? 'pageX' : 'pageY';

  const [isMoving, setIsMoving] = useState(false);

  // Line position when mousedown
  const [initialPosition, setInitialPosition] = useState();

  // Cursor position when mousedown
  const [cursorStart, setCursorStart] = useState();

  const documentMouseMoveHandler = useMemo(
    () => (e) => {
      if (!isMoving) return;

      e.stopPropagation();
      e.preventDefault();

      const movement = cursorStart - e[cursorPositionKey];

      const newPosition = positionLimit
        ? positionLimit(initialPosition - movement)
        : initialPosition - movement;

      setPosition(newPosition);
    },
    [isMoving, isVertical, cursorStart, position]
  );
  const documentMouseUpHandler = useMemo(
    () => () => {
      if (!isMoving) return;

      setIsMoving(false);
      onChange(position);
    },
    [isMoving, onChange, position]
  );

  useEffect(() => {
    document.addEventListener('mousemove', documentMouseMoveHandler);
    document.addEventListener('mouseup', documentMouseUpHandler);

    return () => {
      document.removeEventListener('mousemove', documentMouseMoveHandler);
      document.removeEventListener('mouseup', documentMouseUpHandler);
    };
  });

  const x1 = isVertical ? position : 0;
  const x2 = isVertical ? position : xMax;
  const y1 = isVertical ? 0 : position;
  const y2 = isVertical ? yMax : position;

  return (
    <>
      {/**
       * This transparent, thicker line is the one handling the mouse events.
       * Otherwise the area for clicking and dragging would be just 2 px.
       */}
      <line
        x1={x1}
        x2={x2}
        y1={y1}
        y2={y2}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setCursorStart(e[cursorPositionKey]);
          setInitialPosition(position);
          setIsMoving(true);
        }}
        style={{
          stroke: 'transparent',
          strokeWidth: 8,
          cursor: isVertical ? 'col-resize' : 'row-resize'
        }}
      />
      {/**
       * This is the actual blue line. It doesn't handle any events.
       */}
      <line
        x1={x1}
        x2={x2}
        y1={y1}
        y2={y2}
        style={{
          stroke: '#2F54EB',
          pointerEvents: 'none',
          strokeWidth: 2
        }}
      />
    </>
  );
}
InteractiveLine.propTypes = {
  type: PropType.string,
  position: PropType.number,
  positionLimit: PropType.func,
  xMax: PropType.number,
  yMax: PropType.number,
  onChange: PropType.func
};

function ShadedBox({ xMin, xMax, yMin, yMax }) {
  return (
    <rect
      width={Math.abs(xMax - xMin)}
      height={Math.abs(yMax - yMin)}
      x={Math.min(xMin, xMax)}
      y={Math.min(yMin, yMax)}
      fill="rgba(47, 84, 235, 0.10)"
    />
  );
}

ShadedBox.propTypes = {
  xMax: PropType.number,
  yMax: PropType.number,
  xMin: PropType.number,
  yMin: PropType.number
};
