import React, { useMemo } from 'react';
/* eslint-disable import/no-unresolved */
import { Bar } from 'react-chartjs-2';
/* eslint-enable import/no-unresolved */

import { getRegionalAnalytics } from 'src/Query/warehouse';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import useDebouncedQuery from 'src/hooks/useDebouncedQuery';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';
import { REGIONS } from 'src/pages/insights/dimensions';
import { reduceRegionalAnalyticsByRegion } from 'src/utils/aggregations';
import { filterToFormTypeRegionOnly } from 'src/utils/filters';

export default function SupplierCapabilityBarChart() {
  const filters = useRelevantFiltersForForm();
  const filterFilters = filterToFormTypeRegionOnly(filters);

  const { region_code__in = [], ...filtersWithoutRegion } = filterFilters || {};

  const { data, isLoading } = useDebouncedQuery(
    ['warehouse', 'regional-analytics', filtersWithoutRegion],
    () => getRegionalAnalytics(filtersWithoutRegion)
  );

  const loadingStateClass = useLoadingStateClass(isLoading);

  const regionCountsAggregate = useMemo(
    () => (data || []).reduce(reduceRegionalAnalyticsByRegion, {}),
    [data]
  );

  const chartData = useMemo(() => {
    const regionKeys = Object.keys(regionCountsAggregate);
    const labels = [];
    const counts = [];
    const colors = [];

    regionKeys.forEach((regionCode) => {
      if (REGIONS[regionCode]) {
        labels.push(REGIONS[regionCode].label);
        counts.push(regionCountsAggregate[regionCode].suppliers);
        colors.push(
          region_code__in.includes(regionCode) ? '#2F54EB' : '#DCE2EB'
        );
      }
    });

    return {
      labels,
      datasets: [
        {
          label: 'Number of Suppliers',
          data: counts,
          backgroundColor: colors,
          barThickness: 42,
          borderWidth: 0
        }
      ]
    };
  }, [regionCountsAggregate, region_code__in]);

  return (
    <ExplorePanel className={loadingStateClass} pad={24}>
      <Bar
        data={chartData}
        options={{
          scales: {
            x: {
              border: {
                width: 1,
                color: '#323640'
              },
              grid: {
                display: false
              }
            },
            y: {
              border: {
                dash: [3, 3],
                display: false
              },
              ticks: {
                maxTicksLimit: 6,
                padding: 12,
                crossAlign: 'far'
              },
              grid: {
                drawTicks: false
              }
            }
          },
          // Ensure chart expands full width
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Suppliers by Region'
            }
          }
        }}
      />
    </ExplorePanel>
  );
}
