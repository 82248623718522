import React from 'react';

import Location from './Location';

const lastDetailedCountValue = (detailedCounts, key) => {
  for (let i = detailedCounts.length - 1; i >= 0; i--) {
    if (detailedCounts[i] !== null) {
      return detailedCounts[i][key]; // Return the last non-null entry
    }
  }
  return 0;
};

export default function BalesExplore() {
  return (
    <div className="bales-explore">
      <Location
        locked // We can't edit supplier list from Explore
      />
    </div>
  );
}
