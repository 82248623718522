import React, { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { setProjectTdsLocation } from 'src/Mutation';
import ValueInputPopover from 'src/components/project/source/editable-cells/ValueInputPopover';
import { locationRender } from 'src/pages/rfq/proposal/supply/SupplyRoute';
import LocationInput from 'src/components/form/LocationInput';

export default function ProjectLocationCell({
  tds,
  allTdss,
  fieldName,
  disabled,
  setQueryData,
  invalidateQueryData,
  messageApi
}) {
  const { mutate, isLoading: updating } = useMutation({
    mutationFn: setProjectTdsLocation,
    onSuccess: (res) => {
      invalidateQueryData();
    },
    onError: () => {
      messageApi.error('Failed to update supplier details');
    }
  });

  const locationMutate = useCallback(
    (v) => {
      const { placeId } = v;
      const id = tds.project_tds_id;
      mutate({ placeId, id });
    },
    [allTdss, fieldName]
  );
  const text = locationRender(
    tds.project_tds_location_id
      ? {
          state: tds.project_tds_location_state,
          country: tds.project_tds_location_country
        }
      : tds
  );

  return (
    <ValueInputPopover
      ValueComponent={LocationInput}
      disabled={disabled}
      loading={updating}
      formTitle="Set new location"
      mutate={(v) => locationMutate(v)}
      value={undefined}
      tds={tds}
    >
      <span className="subcontent">{text}</span>
    </ValueInputPopover>
  );
}
ProjectLocationCell.propTypes = {
  tds: PropTypes.object,
  allTdss: PropTypes.array,
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  setQueryData: PropTypes.func,
  invalidateQueryData: PropTypes.func,
  messageApi: PropTypes.object
};
