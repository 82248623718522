import React, { useMemo } from 'react';
import { LockFilled, PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import ThunderboltIcon from 'src/components/icons/Thunderbolt';
import useIsConcierge from 'src/hooks/useIsConcierge';
import { useSidebarContext } from './SidebarContext';
import SidebarCascade from './SidebarCascade';

export default function ProjectExploreCascade() {
  const { projects, clearCascades, setActiveProjectId } = useSidebarContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const concierge = useIsConcierge();
  const exploreProjects = useMemo(
    () => [
      ...(concierge
        ? [
            {
              key: `playground`,
              name: 'Playground',
              icon: <ThunderboltIcon />
            }
          ]
        : [
            {
              key: `new`,
              params: { new: 1 },
              name: 'New Project',
              icon: <PlusOutlined />
            }
          ]),
      ...(projects?.length
        ? projects
            // .filter((p) => p.stage === 'explore')
            .map((p) => ({
              key: p.uuid,
              uuid: p.uuid,
              name: p.name || 'Project',
              icon: p.stage !== 'explore' ? <LockFilled /> : undefined
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
        : [])
    ],
    [projects, pathname]
  );

  const onProjectSelectItem = (p) => {
    setActiveProjectId(p.key);
    navigate(
      `/project/explore/${p.key}/?${
        p.project.params ? new URLSearchParams(p.project.params).toString() : ''
      }`
    );
    clearCascades();
  };

  const cascadeItems = useMemo(
    () =>
      exploreProjects.map((project) => ({
        label: project.name,
        key: project.uuid || 'playground',
        icon: project.icon,
        active: pathname.includes(project.uuid || 'playground'),
        project,
        next: true
      })),
    [exploreProjects]
  );
  return (
    <SidebarCascade
      key="explore"
      title="Explore"
      items={cascadeItems}
      selectItem={onProjectSelectItem}
      allowSearch
    />
  );
}
