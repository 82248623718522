import React from 'react';
import { ReactComponent as Icon } from './svg/more.svg';
import SvgIconComponent from './SvgIconComponent';

function IconComponent(props) {
  return (
    <SvgIconComponent {...props}>
      <Icon />
    </SvgIconComponent>
  );
}

export default IconComponent;
