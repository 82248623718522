import React, { useEffect } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import ProjectQuotes from 'src/pages/project/ProjectQuotes';
import BuyerDigitalRfq from 'src/pages/project/BuyerDigitalRfq';
import ConciergeContextProvider from 'src/components/concierge/ConciergeContext';
import SupplierList from 'src/components/project/source/SupplierList';
import ProjectSourceWorkflow from 'src/pages/project/ProjectSourceWorkflow';
import PropTypes from 'prop-types';
import { getNextStep } from 'src/components/project/source/helpers';

export default function SourceRoutes() {
  return (
    <Routes>
      <Route path="/:projectId/*" element={<SourceSubroutes />} />
    </Routes>
  );
}

function SourceSubroutes() {
  const { projectId } = useParams();
  const { getProjectbyId } = useSidebarContext();

  const project = getProjectbyId(projectId);

  if (!project || project.uuid !== projectId) return <Skeleton />;

  const filtersForWorkflow = {
    ...(project?.workflow?.['prioritize-suppliers']?.filters ||
      project?.filters ||
      {})
  };

  const drfqSent = project && ['procure', 'manage'].includes(project.stage);

  return (
    <ConciergeContextProvider
      values={{
        explore: {
          filters: {
            ...filtersForWorkflow
          }
        }
      }}
    >
      <div className="project-source">
        <Row wrap={false} className="individually-scrolling-cols">
          <Col className="project-source--sidebar">
            <ProjectSourceWorkflow projectId={projectId} />
          </Col>
          <Col className="project-source--content">
            <Routes>
              <Route
                path="/prioritize-suppliers"
                element={<SupplierList locked={drfqSent} />}
              />
              <Route
                path="/buyer-digital-rfq"
                element={
                  <BuyerDigitalRfq project={project} locked={drfqSent} />
                }
              />
              <Route path="/quotes" element={<ProjectQuotes />} />
              <Route
                path="/"
                element={<RedirectToNextStep project={project} />}
              />
            </Routes>
          </Col>
        </Row>
      </div>
    </ConciergeContextProvider>
  );
}

function RedirectToNextStep({ project }) {
  const navigate = useNavigate();
  const nextStep = getNextStep(project);

  useEffect(() => {
    navigate(nextStep.key);
  }, []);

  return null;
}
RedirectToNextStep.propTypes = {
  project: PropTypes.object
};
